import React, { useEffect, useState } from "react";
import CKEditor from "react-ckeditor-component";
import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import {
  addTestimonials_API,
  dispatchLoadingStatus,
  editTestimonial_API,
} from "../../API/Action/globalAction";

// Added by Parth Roka
function PagesAdd(props) {
  const [propsValue, setPropsValue] = useState();
  const dispatch = useDispatch({});
  let htmlin = `<h3>The Sustainable Fabrics is not just a company, it is a mindset - <b> to create a Sustainable Future. </b> We are committed to building the most customer-centric sustainable solution for all fabric needs. <b> Join us in this movement and help us take you in the right direction!” </b></h3>`;
  const [fname, setFname] = useState("");
  const [content, setContent] = useState(htmlin);
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [roleError2, setRoleError2] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");
  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");
  let token = localStorage.getItem("token");
  let nameRe = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
  let nameRe2 = /^[a-zA-Z\s-']*$/;

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/TestimonialList";
      } else {
        console.log("Cancel press");
      }
    });
  }

  useEffect(() => {
    if (props.data) {
      console.log("myprops", props.data);
      setPropsValue(props.data);
      setFname(props.data.name);
      setSelectedFile(props.data.image);
      setContent(props.data.short_description);
      setRole(props.data.role);
    }
  }, [props]);


  useEffect(() => {
    if (!token) {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }

  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")
      ) {
        setSelectedFile(file);
        setSelectedFileError2("");
      } else {
        setSelectedFileError2(
          "The image must be a file of type: jpg, png, jpeg"
        );
      }
    } else {
      setSelectedFileError2("");
    }
  };

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";
    setSelectedFile(null);
    setSelectedFile2(null);
  };

  function handleFname(e) {
    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z-']/g, '');

    setFname(data.replace(/[^a-zA-Z-']/g, ''))

    if (data2) {

      if (data2.length >= 2) {
        setNameError(false);
        if (!nameRe2.test(data)) {
          setNameError(true);
          setNameError2("Please enter a valid name");


        }
        else {
          setNameError(false);
          setNameError2("");

        }
      }
      else {
        setNameError(true);
        setNameError2("Name must be  2 to 100 characters long");

      }
    }
    else {
      setNameError(true);
      setNameError2("Please enter name");


    }
  }

  function handleRole(e) {
    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z-]/g, '');
    setRole(data.replace(/[^a-zA-Z-]/g, ''))

    if (data2) {

      if (data2.length >= 2) {
        setRoleError(false);
        if (!nameRe.test(data)) {
          setRoleError(true);
          setRoleError2("Please enter a valid role");
        }
        else {
          setRoleError(false);
          setRoleError2("");
        }
      }
      else {
        setRoleError(true);
        setRoleError2("Role must be  2 to 50 characters long");

      }
    }
    else {
      setRoleError(true);
      setRoleError2("Please enter role");


    }
  }

  const handleChange = (evt) => {
    const newContent = evt.editor.getData();

    setContent(newContent);

    if (newContent) {
      setContentError(false);
      setContentError2("");
    } else {
      setContentError(true);
      setContentError2("Please enter description");
    }
  };


  function handleSubmit() {
    let data3 = role.trim() == "" ? "" : role.replace(/[^a-zA-Z-]/g, '');
    if (data3) {

      if (data3.length >= 2) {
        if (!nameRe2.test(role)) {
          setRoleError2("Please enter a valid role");

        }
        else {
          setRoleError2("");

        }
      }
      else {
        setRoleError2("Role must be  2 to 50 characters long");

      }
    }
    else {
      setRoleError2("Please enter role");


    }
    let data2 = fname.trim() == "" ? "" : fname.replace(/[^a-zA-Z-']/g, '');

    if (data2) {

      if (data2.length >= 2) {
        if (!nameRe2.test(fname)) {
          setNameError2("Please enter a valid name");
        }
        else {
          setNameError2("");

        }
      }
      else {
        setNameError2("Name must be  2 to 100 characters long");

      }
    }
    else {
      setNameError2("Please enter name");
    }

    if (content) {
      setContentError2("");
    } else {
      setContentError2("Please enter description");
    }

    if (selectedFile) {
      setSelectedFileError2("");
    } else {
      setSelectedFileError2("Please select image");
    }

    if (
      nameError == false &&
      selectedFileError == false &&
      contentError == false &&
      roleError == false &&
      fname &&
      content &&
      role &&
      selectedFile
    ) {

      let formData = new FormData();
      formData.append("image", selectedFile ? selectedFile : "");
      formData.append("short_description", content ? content : "");
      formData.append("name", fname);
      formData.append("role", role);

      let data = propsValue
        ? propsValue.testimonials_id
          ? propsValue.testimonials_id
          : ""
        : "";
      if (data) {
        formData.append("testimonials_id", data);
        dispatch(editTestimonial_API(formData));
        dispatch(dispatchLoadingStatus(true));
        setNameError2("");
        setContentError2("");
        setSelectedFileError2("");
        setRoleError2("");
      } else {
        dispatch(addTestimonials_API(formData));
        dispatch(dispatchLoadingStatus(true));
        setNameError2("");
        setContentError2("");
        setSelectedFileError2("");
        setRoleError2("");
      }
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />
        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.testimonials_id
                                  ? "Testimonial Edit"
                                  : "Testimonial Add"
                                : "Testimonial Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/TestimonialList">Testimonial List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.testimonials_id
                                  ? "Testimonial Edit"
                                  : "Testimonial Add"
                                : "Testimonial Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Name <span className="text-danger">*</span></label>
                              <input
                                placeholder="Enter Name"
                                type="text"
                                className="form-control"
                                value={fname}
                                maxLength={100}
                                onChange={(e) => handleFname(e)}
                              />
                              <p className="errorr">{nameError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">
                                  Upload Image <span className="text-danger">*</span>
                                </label>
                                <form>
                                  <div className="file">
                                    <div
                                      className="file__input"
                                      id="file__input"
                                    >
                                      <input
                                        className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label
                                        className="file__input--label"
                                        accept=".png, .jpg .jpeg"
                                        htmlFor="customFile"
                                        data-text-btn="Choose Files"
                                      >
                                        Add image:
                                      </label>
                                    </div>
                                  </div>
                                </form>
                                <p className="errorr">{selectedFileError2}</p>

                                {selectedFile && (
                                  <div
                                    className="choosed-files"
                                    style={{ position: "relative" }}
                                  >
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={
                                        typeof selectedFile === "string"
                                          ? selectedFile
                                          : URL.createObjectURL(selectedFile)
                                      }
                                      alt="Selected Preview"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "200px",
                                      }}
                                    />

                                    <CloseSvg
                                      className="point"
                                      width={18}
                                      height={18}
                                      onClick={() => handleRemoveFile()}
                                      style={{
                                        zIndex: "99",
                                        position: "absolute",
                                        right: "8px",
                                        top: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Role <span className="text-danger">*</span></label>
                              <input
                                placeholder="Enter Role"

                                type="text"
                                className="form-control"
                                value={role}
                                maxLength={50}
                                onChange={(e) => handleRole(e)}
                              />
                              <p className="errorr">{roleError2}</p>
                            </div>

                            <div className="form-group col-sm-12">
                              <label>Long Description <span className="text-danger">*</span></label>
                              <CKEditor
                                placeholder="Enter Name"
                                activeclassName="p10"
                                content={content}
                                events={{
                                  change: handleChange,
                                }}
                                config={{ allowedContent: true }}
                                maxLength={250}
                              />
                              <p className="errorr">{contentError2}</p>
                            </div>

                          </div>

                          <button
                            className="btn iq-bg-danger mr-2"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>

                          <button
                            className="btn btn-primary "
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PagesAdd;
