import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Testimonialslist_API, Testimonialsstatus_API, accessDatadetails_API, dispatchLoadingStatus, displaySwal } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Loader from '../Loader/Loader';
import moment from 'moment';

// Added by Parth Roka
function List() {
  let TestimonialslistReducer = useSelector(state => state.globalReducer.TestimonialslistReducer);
  let pagesStatusReducer = useSelector(state => state.globalReducer.pagesStatusReducer);
  let TestimonialsstatusReducer = useSelector(state => state.globalReducer.TestimonialsstatusReducer);
  let token = localStorage.getItem("token");
  const [page, setpage] = useState(0);
  const [startValue, setStartValue] = useState(0);
  const totalCount = TestimonialslistReducer ? TestimonialslistReducer.count : "";
  const itemsPerPage = 5;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  let history = useNavigate();
  const dispatch = useDispatch({});
  let accessDatadetailsReducer = useSelector(
    (state) => state.globalReducer.accessDatadetailsReducer
  );
  const Login_Parse = JSON.parse(accessDatadetailsReducer && accessDatadetailsReducer.data[0].accessData)
  const [order, setOrder] = useState("ASC");
  const [datasrc, setdatasrc] = useState('');

  const handlePageChange = (page, count) => {
    setCurrentPage(page);
    Apicall((page - 1) * itemsPerPage)
  };

  const renderPagination = () => {
    const pagesToShow = 3;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    const paginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link" style={{ cursor: TestimonialslistReducer && TestimonialslistReducer.count > 5 ? 'pointer' : '' }} onClick={() => { handlePageChange(i); setpage(i) }}>
            {i}
          </a>
        </li>
      );
    }

    return paginationItems.length > 0 ? paginationItems : <li className="page-item disabled"><span className="page-link">No pages</span></li>;
  };

  function Apicall(start) {
    let formData = new FormData();
    formData.append('token', token ? token : "");
    formData.append("start", start);
    formData.append("limit", 5);
    setStartValue(start);
    setpage(1);
    dispatch(dispatchLoadingStatus(true))
    dispatch(Testimonialslist_API(formData));
  }

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1));
      console.log("datasrc", datasrc);
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  const Sortt22 = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    if (TestimonialslistReducer) {
      if (TestimonialslistReducer.errorcode == 1) {
        setdatasrc([]);
      } else {
        setdatasrc(TestimonialslistReducer ? (TestimonialslistReducer.data ? TestimonialslistReducer.data : []) : []);
      }
    }
  }, [TestimonialslistReducer]);

  useEffect(() => {
    if (token) {
      if (!window.location.href.includes("TestimonialList")) {
        window.location.href = window.location.origin + "/TestimonialList";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
    dispatch(dispatchLoadingStatus(true))
    let formData = new FormData();
    let formData2 = new FormData();
    const access_id = localStorage.getItem("user_idd")
    formData.append('token', token ? token : "");
    formData.append("start", 0);
    formData.append("limit", 5);
    setStartValue(0);
    setpage(1);

    formData2.append("user_id", access_id)
    dispatch(accessDatadetails_API(formData2))
    dispatch(Testimonialslist_API(formData))



  }, [])

  useEffect(() => {
    if (pagesStatusReducer) {
      Apicall((currentPage - 1) * itemsPerPage)
    }
  }, [pagesStatusReducer])

  useEffect(() => {
    if (TestimonialsstatusReducer) {
      Apicall((currentPage - 1) * itemsPerPage)
    }
  }, [TestimonialsstatusReducer])
  function toggleSwitch(e, i) {

    console.log("check", e.target.checked);

    var vall = i.status == 0 ? "active" : "inactive";
    {
      Login_Parse && Login_Parse.TestimonialsEdit == true ?
        swal({
          text: "Are you sure you want to " + vall + " this testimonial?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              let formData = new FormData();
              formData.append("status", e.target.checked == true ? "0" : "1");
              formData.append("testimonials_id", i.testimonials_id);
              dispatch(dispatchLoadingStatus(true));
              dispatch(Testimonialsstatus_API(formData, i.status));

            }
          }) : displaySwal()
    }
  }
  return (
    <>
      <div className="wrapper">

        <Sidebar />
        <Loader />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Testimonial List</h4>
                    </div>

                  </div>
                  <div className="iq-card-body">
                    <div className="table-responsive">
                      <div className="row justify-content-between">
                        <div className="col-sm-12 col-md-6">
                          <div id="user_list_datatable_info" className="dataTables_filter">
                            <form className="mr-3 position-relative">
                              <div className="form-group mb-0">

                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="user-list-files d-flex float-right">
                            <span className="table-add float-right mb-3 mr-2">
                              {Login_Parse?.TestimonialsAdd == true ?
                                <button className="btn btn-sm iq-bg-success" onClick={() => history('/TestimonialAdd')}><i
                                  className="ri-add-fill"></i><span className="pl-1">Add New</span>
                                </button>
                                :
                                <button className="btn btn-sm iq-bg-success" onClick={() => displaySwal()}><i
                                  className="ri-add-fill"></i><span className="pl-1">Add New</span>
                                </button>
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Image</th>
                            <th>Name
                              <i onClick={() => Sortt("name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Role
                              <i onClick={() => Sortt("role")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Status</th>
                            <th>Date
                              <i onClick={() => Sortt22("created_at")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {datasrc && datasrc.length > 0 ? datasrc.map((i, index) =>
                            <tr>
                              <td> {((page * 5) + index + 1) - 5}.</td>
                              <td className="text-center"><img className="rounded-circle img-fluid avatar-40" src={i.image} alt="profile" /></td>
                              <td>{i.name}</td>
                              <td>{i.role}</td>
                              <td>
                                <div className="switch-container">
                                  <label className="switch-label">
                                    <input
                                      type="checkbox"
                                      key={i.id}
                                      className="switch-input"
                                      checked={i.status}
                                      defaultChecked={i.status}
                                      onClick={(e) => toggleSwitch(e, i)}
                                    />
                                    <span className="switch-slider"></span>
                                  </label>
                                </div>

                              </td>
                              <td>{i.created_at
                                ? moment(i.created_at).format("MM/DD/YYYY")
                                : "-"}</td>
                              <td>
                                <div className="flex align-items-center list-user-action">
                                  {Login_Parse?.TestimonialsEdit == true ?
                                    <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => history('/TestimonialEdit', { state: i })}><i className="ri-pencil-line point"></i></a>
                                    :
                                    <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => displaySwal()}><i className="ri-pencil-line point"></i></a>
                                  }
                                </div>
                              </td>
                            </tr>
                          ) : <tr className='bg-white border-0'> <td colSpan={6} className='border-0'> <div className='col-md-12 w-100 mt-4'><p className='text-center'>No Data Found</p> </div></td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <div className="row justify-content-between mt-3">
                      <div id="user-list-page-info" className="col-md-6">
                        {
                          TestimonialslistReducer && TestimonialslistReducer.count ?
                            <span>
                              Showing {startValue + 1} to {TestimonialslistReducer.count > startValue + 5 ? startValue + 5 : TestimonialslistReducer.count} of {TestimonialslistReducer.count} entries
                            </span>
                            : ""}
                      </div>
                      <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end mb-0">
                            <li className={`page-item  ${currentPage === 1 ? 'disabled' : 'page-item point'}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage - 1, "less")} tabIndex="-1" aria-disabled="true">
                                Previous
                              </a>
                            </li>
                            {renderPagination()}
                            <li className={`page-item  ${currentPage === totalPages ? 'disabled' : 'page-item point'}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default List 