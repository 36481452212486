import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Login from './Componets/Authentication/Login';
import Inquiry from './Componets/Inquery/List';

import PagesAdd from './Componets/Pages/Add';
import PagesList from './Componets/Pages/List';
import PagesEdit from './Componets/Pages/Edit';

import ProductEdit from './Componets/Products/Edit';
import ProductList from './Componets/Products/List';
import ProductAdd from './Componets/Products/Add';

import OurTeamAdd from './Componets/Our Story Team/Add';
import OurTeamList from './Componets/Our Story Team/List';
import OurTeamEdit from './Componets/Our Story Team/Edit';

import LocationAdd from './Componets/Contact/Add';
import ContactEdit from './Componets/Contact/Edit';
import LocationList from './Componets/Contact/List';

import FebricsCategoryAdd from './Componets/Bedsheet/Febrics Category/Add';
import FebricsCategoryEdit from './Componets/Bedsheet/Febrics Category/Edit';
import FebricsCategoryList from './Componets/Bedsheet/Febrics Category/List';

import AdminUserManagementAdd from './Componets/Admin User Management/Add';
import AdminUserManagementEdit from './Componets/Admin User Management/Edit';
import AdminUserManagementList from './Componets/Admin User Management/List';

import TestimonialAdd from './Componets/Testimonial/Add';
import TestimonialEdit from './Componets/Testimonial/Edit';
import TestimonialList from './Componets/Testimonial/List';

import HomePageHighlightsList from './Componets/Environment Selection/List'
import HomePageHighlightsEdit from './Componets/Environment Selection/Edit'

import ForgotPassword from './Componets/Authentication/Forgot'
import Otp from './Componets/Authentication/Otp'
import ResetPassword from './Componets/Authentication/ResetPassword';


// Added by Parth Roka
function App() {

  useEffect(() => {

    if (window.location.href == window.location.origin + '/') {
      if (localStorage.getItem('token')) {
        window.location.href = window.location.origin + '/HomePageHighlightsList'
      } else {
        window.location.href = window.location.origin + '/Login'
      }
    }
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>

          <Route exact path="/Login" element={<Login />} />

          <Route exact path="/PagesAdd" element={<PagesAdd />} />
          <Route exact path="/PagesList" element={<PagesList />} />
          <Route exact path="/PagesEdit" element={<PagesEdit />} />

          <Route exact path="/ProductAdd" element={<ProductAdd />} />
          <Route exact path="/ProductList" element={<ProductList />} />
          <Route exact path="/ProductEdit" element={<ProductEdit />} />


          <Route exact path="/OurTeamAdd" element={<OurTeamAdd />} />
          <Route exact path="/OurTeamList" element={<OurTeamList />} />
          <Route exact path="/OurTeamEdit" element={<OurTeamEdit />} />


          <Route exact path="/LocationAdd" element={< LocationAdd />} />
          <Route exact path="/LocationEdit" element={<ContactEdit />} />
          <Route exact path="/LocationList" element={<LocationList />} />

          <Route exact path="/FabricsCategoryAdd" element={< FebricsCategoryAdd />} />
          <Route exact path="/FabricsCategoryEdit" element={<FebricsCategoryEdit />} />
          <Route exact path="/FabricsCategoryList" element={<FebricsCategoryList />} />

          <Route exact path="/Inquiry" element={<Inquiry />} />

          <Route exact path="/AdminUserManagementAdd" element={< AdminUserManagementAdd />} />
          <Route exact path="/AdminUserManagementEdit" element={<AdminUserManagementEdit />} />
          <Route exact path="/AdminUserManagementList" element={<AdminUserManagementList />} />

          <Route exact path="/TestimonialAdd" element={< TestimonialAdd />} />
          <Route exact path="/TestimonialEdit" element={<TestimonialEdit />} />
          <Route exact path="/TestimonialList" element={<TestimonialList />} />

          <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
          <Route exact path="/Otp" element={<Otp />} />
          <Route exact path="/ResetPassword" element={<ResetPassword />} />

          <Route exact path="/HomePageHighlightsEdit" element={<HomePageHighlightsEdit />} />
          <Route exact path="/HomePageHighlightsList" element={<HomePageHighlightsList />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;
