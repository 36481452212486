import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Login_API,
  dispatchLoadingStatus,
} from "../../API/Action/globalAction";
import Loader from "../Loader/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// Added by Parth Roka
function Login() {

  let userRem = localStorage.getItem("sendEmail");
  let passwordRem = localStorage.getItem("password");
  const [email, setEmail] = useState(userRem ? userRem : "");
  const [password, setPassword] = useState(passwordRem);
  const [emailError, setEmailError] = useState("");
  const [emailFlag, setEmailFlag] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [eyes, setEyes] = useState(false);
  const [remember, setRemember] = useState(false);

  const toggleBtn = () => {
    setEyes((preState) => !preState);
  };

  const dispatch = useDispatch({});
  let history = useNavigate();

  let AdminLoginReg = useSelector((state) => state.globalReducer.LoginReducer);
  function isValidEmail(email) {

    return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
  }

  const handleEmail = (e) => {
    let data = e.target.value.trim();
    setEmail(data)
    if (data) {
      setEmailError("");
      setEmailFlag(false);

      if (!isValidEmail(data)) {
        setEmailFlag(true);
        setEmailError(<p style={{ color: '#bc2a2a' }}>Please enter a valid email</p>)
      }
      else {
        setEmailError("");
        setEmailFlag(false);
      }
    }
    else {
      setEmailFlag(true);
      setEmailError(<p style={{ color: '#bc2a2a' }}>Please enter email</p>);
    }
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);

    if (!e.target.value) {
      setPasswordFlag(true);
      setPasswordError(<p className="errorr">Please enter password</p>);
    } else {
      if (e.target.value.length < 6) {
        setPasswordError(<p className="errorr">Password must be  6 to 16 characters long</p>);
        setPasswordFlag(true);

      }
      else {
        setPasswordError("");
        setPasswordFlag(false);

      }
    }
  };

  // Main Function Added by Parth Roka
  function handleSubmit() {

    if (email) {
      setEmailError("");
      if (email.length > 5) {
        setEmailError("")
        if (!isValidEmail(email)) {
          setEmailError(<p style={{ color: '#bc2a2a' }}>Please enter a valid email</p>)
        }
        else {
          setEmailError("")

        }
      }
      else {
        setEmailError(<p style={{ color: '#bc2a2a' }}>Please enter a valid email</p>)
      }

    }
    else {
      setEmailError(<p style={{ color: '#bc2a2a' }}>Please enter email</p>);

    }

    if (password) {

      if (password.length < 6) {
        setPasswordError(<p className="errorr">Password must be  6 to 16 characters long</p>);
      } else {
        setPasswordError("");
      }
    } else {
      setPasswordError(<p className="errorr">Please enter password</p>);
    }

    if (email && password && !passwordFlag && !emailFlag) {
      localStorage.setItem("sendEmail", remember ? email : "");
      localStorage.setItem("password", remember ? password : "");
      localStorage.setItem("isremembersus", remember);

      let formData = new FormData();

      formData.append("email", email);
      formData.append("password", password);

      dispatch(dispatchLoadingStatus(true));
      dispatch(Login_API(formData, email));
    }
  }

  // Enter Key Press Added by Mansi Vaghela
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    localStorage.removeItem("resendValue");
    localStorage.removeItem("emailAdmin");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("otp_id");
    localStorage.removeItem("admin_id");
    localStorage.removeItem("token");
    localStorage.removeItem("myotp");

    const token = localStorage.getItem("token");

    if (token) {
      if (!window.location.href.includes("HomePageHighlightsList")) {
        window.location.href = window.location.origin + "/HomePageHighlightsList";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
      var isremembersus = localStorage.getItem("isremembersus");
      if (isremembersus) {
        setEmail(localStorage.getItem("sendEmail"));
        setPassword(localStorage.getItem("password"));
      } else {
        setEmail("");
        setPassword("");
      }
      setRemember(
        localStorage.getItem("isremembersus") == "true" ? true : false
      );
    }
  }, []);
  useEffect(() => {
    if (AdminLoginReg) {

      history("/HomePageHighlightsList");
    }
  }, [AdminLoginReg]);

  return (

    <section className="sign-in-page bg-white">
      <Loader />
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-sm-6 align-self-center">
            <div className="sign-in-from">
              <h1 className="mb-0">Sign in</h1>
              <p>
                Enter your email address and password to access admin panel.
              </p>
              <div className="mt-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address*</label>
                  <input
                    value={email}
                    onChange={(e) => handleEmail(e)}
                    className="form-control mb-0"
                    id="exampleInputEmail1"
                    placeholder="Enter email"
                    onKeyDown={handleKeyPress}
                  />
                  {emailError}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password*</label>

                  <div style={{ position: "relative" }}>
                    <input
                      type={eyes ? "text" : "password"}
                      className="form-control mb-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={password}
                      maxLength={16}

                      onChange={handlePassword}
                      onKeyDown={handleKeyPress}
                    />
                    <button
                      style={{ position: "absolute", top: "-5px", right: "0" }}
                      className="btn "
                      type="button"
                      id="password-addon"
                      onClick={toggleBtn}
                    >
                      {eyes ? (
                        <FaEye style={{ width: "18", height: "18" }} />
                      ) : (
                        <FaEyeSlash style={{ width: "18", height: "18" }} />
                      )}
                    </button>
                  </div>
                  {passwordError}

                  <center>
                  </center>

                </div>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <div className="custom-control custom-checkbox d-inline-block "  >
                    {remember ? (
                      <img
                        onClick={() => setRemember(!remember)}
                        className="form-check-input"
                        src="images/checked.svg"
                        style={{ height: 15, width: 15 }}
                      />
                    ) : (
                      <img
                        onClick={() => setRemember(!remember)}
                        className="form-check-input"
                        src="images/checkbox-empty.svg"
                        style={{ height: 15, width: 15 }}
                      />
                    )}
                    <label
                      onClick={() => setRemember(!remember)}
                      htmlFor="customCheck1"
                      style={{ cursor: "pointer" }}
                    >
                      Remember Me
                    </label>
                  </div>
                  <a href="/ForgotPassword" className="float-right">
                    Forgot password?
                  </a>

                </div>
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                    onClick={() => handleSubmit()}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 text-center">
            <div
              className="sign-in-detail text-white"
              style={{
                background: "url(images/login/2.jpg) no-repeat 0 0",
                backgroundSize: "cover",
              }}
            >
              <div
                className=""
                data-autoplay="true"
                data-loop="true"
                data-nav="false"
                data-dots="true"
                data-items="1"
                data-items-laptop="1"
                data-items-tab="1"
                data-items-mobile="1"
                data-items-mobile-sm="1"
                data-margin="0"
              >
                <div className="item">
                  <img
                    src="images/front main.png"
                    className="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 className="text-white" style={{ marginBottom: "10px" }}>
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </h4>
                  <p>
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
