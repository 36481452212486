import React from 'react';
import Add from './Add';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';

// Added by Mansi Vaghela
function FebricsCategoryEdit(props) {
  const { state } = useLocation();
  return (
    <>
      <div className="wrapper">
        <Sidebar/>
        <Add data={state}/>
      </div>
    </>
  )
}

export default FebricsCategoryEdit