import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { ProductList_API, accessDatadetails_API, dispatchLoadingStatus, displaySwal, getDeals_API, getPriceRange_API, getfabricCategory_API, productStatus_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Loader from '../Loader/Loader';
import Select from 'react-select';

// Added by Parth Roka
function List() {
  let productListReducer = useSelector(state => state.globalReducer.productListReducer);
  let range = [{ label: "5", value: "5" }, { label: "10", value: "10" }, { label: "25", value: "25" }, { label: "50", value: "50" }, { label: "100", value: "100" },];
  const [sendRange22, setSendRange22] = useState({ label: "5", value: "5" });
  const [startValue, setStartValue] = useState(0);
  const [sendFebric, setSendFebric] = useState();
  const [sendDeals, setSendDeals] = useState();
  const [sendRange, setSendRange] = useState();
  const [febricCategory, setFebricCategory] = useState();
  const [dealCategory, setDealCategory] = useState();
  const [rangeCategory, setRangeCategory] = useState();
  const [order, setOrder] = useState("ASC");
  const [datasrc, setdatasrc] = useState('');

  let getfabricCategoryReducer = useSelector(state => state.globalReducer.getfabricCategoryReducer);
  let getDealsReducer = useSelector(state => state.globalReducer.getDealsReducer);
  let getPriceRangeReducer = useSelector(state => state.globalReducer.getPriceRangeReducer);
  let fabricCat = febricCategory && febricCategory.filter((i) => i.status == 1)
  let dealCat = dealCategory && dealCategory.filter((i) => i.status == 1)
  let rangeCat = rangeCategory && rangeCategory.filter((i) => i.status == 1)
  let productStatusReducer = useSelector(state => state.globalReducer.productStatusReducer);
  let token = localStorage.getItem("token");
  let accessDatadetailsReducer = useSelector(
    (state) => state.globalReducer.accessDatadetailsReducer
  );

  const Login_Parse = JSON.parse(accessDatadetailsReducer && accessDatadetailsReducer.data[0].accessData)
  const [page, setpage] = useState(1);
  const totalCount = productListReducer ? productListReducer.count : "";
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

    // Sorting Added by Parth Roka
  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1));
      console.log("datasrc", datasrc);
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

   // Number Sorting Added by Parth Roka
  const Sortt22 = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    if (productListReducer) {
      if (productListReducer.errorcode == 1) {
        setdatasrc([]);
      } else {
        setdatasrc(productListReducer ? (productListReducer.data ? productListReducer.data : []) : []);
      }
    }
  }, [productListReducer]);

  useEffect(() => {
    if (getfabricCategoryReducer) {
      if (getfabricCategoryReducer.errorcode == 0) {
        setFebricCategory(getfabricCategoryReducer.data);

      }
      else {
        setFebricCategory([]);

      }

    }

  }, [getfabricCategoryReducer])

  useEffect(() => {
    if (getPriceRangeReducer) {
      if (getPriceRangeReducer.errorcode == 0) {
        setRangeCategory(getPriceRangeReducer.data);
      }
      else {
        setRangeCategory([]);
      }
    }

  }, [getPriceRangeReducer])

  useEffect(() => {
    if (getDealsReducer) {
      if (getDealsReducer.errorcode == 0) {
        setDealCategory(getDealsReducer.data);
      }
      else {
        setDealCategory([]);
      }
    }
  }, [getDealsReducer])

  const handlePageChange = (page, count) => {
    setCurrentPage(page);
    if (productListReducer && productListReducer.count > itemsPerPage) {
      Apicall((page - 1) * itemsPerPage, sendRange22 && sendRange22.value)

    }
  };

  function handleRange(e) {
    let data = e.value;
    setSendRange22(e);

    if (data) {
      Apicall(0, data);
      setItemsPerPage(data)
      setCurrentPage(1);

    }

  }
  const renderPagination = () => {
    const pagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    const paginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link" style={{ cursor: productListReducer && productListReducer.count > itemsPerPage ? 'pointer' : '' }} onClick={() => { handlePageChange(i); setpage(i) }}>
            {i}
          </a>
        </li>
      );
    }

    return paginationItems.length > 0 ? paginationItems : <li className="page-item disabled"><span className="page-link">No pages</span></li>;
  };

  let history = useNavigate();
  const dispatch = useDispatch({});

  useEffect(() => {
    Apicall(0)

  }, [sendDeals])
  useEffect(() => {
    Apicall(0)

  }, [sendFebric])
  useEffect(() => {
    Apicall(0)

  }, [sendRange])

  useEffect(() => {
    if (sendFebric) {
      Apicall(0);
    }
  }, [sendFebric])

  useEffect(() => {
    if (productStatusReducer) {
      Apicall((page - 1) * itemsPerPage, sendRange22 && sendRange22.value)
    }
  }, [productStatusReducer])

  function Apicall(start, data) {
    let formData = new FormData();
    let formData2 = new FormData();
    const access_id = localStorage.getItem("user_idd")

    formData.append('token', token ? token : "");
    formData.append("start", start);
    formData.append("limit", data ? data : "5");
    setStartValue(start);
    setpage(1);
    formData.append("deals_id", sendDeals ? sendDeals.value : "");
    formData.append("fabricCategory_id", sendFebric ? sendFebric.value : "");
    formData.append("price", sendRange ? sendRange.label : "");
    formData2.append("user_id", access_id)
    dispatch(accessDatadetails_API(formData2))
    dispatch(dispatchLoadingStatus(true))
    dispatch(ProductList_API(formData));


  }
  useEffect(() => {
    if (token) {
      if (!window.location.href.includes("ProductList")) {
        window.location.href = window.location.origin + "/ProductList";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
    dispatch(dispatchLoadingStatus(true))
    let formData = new FormData();
    formData.append("start", 0);
    setStartValue(0);
    setpage(1);

    formData.append("limit", sendRange22 && sendRange22.value);
    formData.append("deals_id", "");
    formData.append("fabricCategory_id", "");
    formData.append("pricerange_id", "");

    dispatch(ProductList_API(formData))
    dispatch(getfabricCategory_API());
    dispatch(getDeals_API());
    dispatch(getPriceRange_API());

  }, [])

  function toggleSwitch(e, i, k) {
    var vall = k.status == 0 ? "active" : "inactive";
    {
      Login_Parse && Login_Parse?.OurTeamEdit == true ?
      swal({
        text: "Are you sure you want to " + vall + " this product?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            let formData = new FormData();
            formData.append("status", e.target.checked == true ? "0" : "1");
            formData.append("product_id", i);
            dispatch(dispatchLoadingStatus(true));
            dispatch(productStatus_API(formData, k.status));

          }
        }) : displaySwal()
    }


  }

  return (
    <>
      <div className="wrapper">

        <Sidebar />
        <Loader />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Products List</h4>
                    </div>

                  </div>
                  <div className="iq-card-body">
                    <div className="">
                      <div className="row justify-content-between">
                        <div className="col-sm-12">
                          <div className="user-list-files d-flex float-right">
                            <span className="table-add float-right mb-3 mr-2">

                              {Login_Parse?.ProductsAdd == true ?
                                <button className="btn btn-sm iq-bg-success" onClick={() => window.location.href = window.location.origin + '/ProductAdd'}><i
                                  className="ri-add-fill"></i><span className="pl-1">Add New Product</span>
                                </button>
                                :
                                <button className="btn btn-sm iq-bg-success" onClick={() => displaySwal()}><i
                                  className="ri-add-fill"></i><span className="pl-1">Add New Product</span>
                                </button>
                              }

                            </span>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className="form-group mb-0">
                            <label htmlFor="fname">Type of Fabric</label>
                            <Select className='w-100'
                              options={fabricCat ? [{ label: 'Select', value: '' }].concat(
                                fabricCat.map((dataItem) => ({
                                  value: dataItem.fabricCategory_id,
                                  label: dataItem.type_of_category,
                                }))) : [{ label: "Select", value: "" }]}
                              placeholder="Select"
                              isClearable={false}
                              value={sendFebric}
                              onChange={(e) => setSendFebric(e)}
                            />
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className="form-group mb-0">
                            <label htmlFor="fname">Deals</label>
                            <Select
                              options={dealCat ? [{ label: 'Select', value: '' }].concat(
                                dealCat.map((dataItem) => ({
                                  value: dataItem.deals_id,
                                  label: dataItem.type_of_price,
                                }))) : [{ value: "", label: "Select" }]}
                              placeholder="Select"
                              isClearable={false}
                              value={sendDeals}
                              onChange={(e) => setSendDeals(e)}
                            />
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className="form-group mb-0">
                            <label htmlFor="fname">Range</label>
                            <Select
                              options={rangeCat ? [{ label: 'Select', value: '' }].concat(
                                rangeCat.map((dataItem) => ({
                                  value: dataItem.pricerange_id,
                                  label: dataItem.range_of_price,
                                }))) : [{ value: "", label: "Select" }]}
                              placeholder="Select"
                              value={sendRange}
                              onChange={(e) => setSendRange(e)}
                            />
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className="form-group mb-0">
                            <label htmlFor="fname">Showing Products</label>
                            <Select className='w-100'
                              options={range ? range.map((dataItem) => ({
                                value: dataItem.value,
                                label: dataItem.label,
                              })) : [{ label: "Select", value: "" }]}
                              placeholder="Select"
                              isClearable={false}
                              value={sendRange22}
                              onChange={(e) => handleRange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Image</th>
                            <th>Product Name
                              <i onClick={() => Sortt("product_name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Description
                              <i onClick={() => Sortt("description")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Discount
                              <i onClick={() => Sortt22("discount")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Price
                              <i onClick={() => Sortt22("price")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Status</th>
                            <th>Date
                              <i onClick={() => Sortt("created_at")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {datasrc && datasrc.length > 0 ? datasrc.map((i, index) =>
                            <tr>
                              <td> {((page * parseInt(sendRange22.value)) + index + 1) - parseInt(sendRange22.value)}.</td>
                              <td className="text-center"><img className="rounded-circle img-fluid avatar-40" src={i.images[0] ? i.images[0].image : null} alt="profile" /></td>
                              <td>{i.product_name}</td>
                              <td>{i.description}</td>
                              <td>{i.discount ? i.discount : "-"}</td>
                              <td>{i.price}</td>
                              <td>
                                <div className="switch-container">
                                  <label className="switch-label">
                                    <input
                                      type="checkbox"
                                      key={i.id}
                                      className="switch-input"
                                      checked={i.status}
                                      defaultChecked={i.status}
                                      onClick={(e) => toggleSwitch(e, i.product_id, i)}
                                    />
                                    <span className="switch-slider"></span>
                                  </label>
                                </div>

                              </td>
                              <td>{i.created_at}</td>

                              <td>
                                <div className="flex align-items-center list-user-action">
                                  {Login_Parse?.ProductsEdit == true ?
                                    <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => history('/ProductEdit', { state: i })}><i className="ri-pencil-line point"></i></a>
                                    :
                                    <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => displaySwal()}><i className="ri-pencil-line point"></i></a>
                                  }
                                </div>
                              </td>
                            </tr>
                          ) : <tr className='bg-white border-0'> <td colSpan={8} className='border-0'> <div className='col-md-12 w-100 mt-4'><p className='text-center'>No Data Found</p> </div></td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <div className="row justify-content-between mt-3">
                      <div id="user-list-page-info" className="col-md-6">
                        {
                          productListReducer && productListReducer.count ?
                            <span>
                              Showing {startValue + 1} to {productListReducer.count > startValue + parseInt(sendRange22.value) ? startValue + parseInt(sendRange22.value) : productListReducer.count} of {productListReducer.count} entries
                            </span>
                            : ""}
                      </div>
                      <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end mb-0">
                            <li className={`page-item  ${currentPage === 1 ? 'disabled' : 'page-item point'}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage - 1, "less")} tabIndex="-1" aria-disabled="true">
                                Previous
                              </a>
                            </li>

                            {renderPagination()}

                            <li className={`page-item  ${currentPage === totalPages ? 'disabled' : 'page-item point'}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



    </>
  )
}

export default List