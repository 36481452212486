import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Sidebar from "../Sidebar/Sidebar";
import Loader from "../Loader/Loader";
import { accessDatadetails_API, dispatchLoadingStatus, displaySwal, environmentalSectionlist_API } from "../../API/Action/globalAction";

// Added by Parth Roka
function List() {

  let environmentalSectionReducer = useSelector(
    (state) => state.globalReducer.environmentalSectionReducer
  );
  let token = localStorage.getItem("token");
  const [page, setpage] = useState(0);
  let history = useNavigate();
  const dispatch = useDispatch({});
  let accessDatadetailsReducer = useSelector(
    (state) => state.globalReducer.accessDatadetailsReducer
  );
  const Login_Parse = JSON.parse(accessDatadetailsReducer && accessDatadetailsReducer.data[0].accessData)
  const [order, setOrder] = useState("ASC");
  const [datasrc, setdatasrc] = useState('');

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1));
      console.log("datasrc", datasrc);
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  const Sortt22 = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    if (environmentalSectionReducer) {
      if (environmentalSectionReducer.errorcode == 1) {
        setdatasrc([]);
      } else {
        setdatasrc(environmentalSectionReducer ? (environmentalSectionReducer.data ? environmentalSectionReducer.data : []) : []);
      }
    }
  }, [environmentalSectionReducer]);

  useEffect(() => {
    if (token) {
      if (!window.location.href.includes("HomePageHighlightsList")) {
        window.location.href = window.location.origin + "/HomePageHighlightsList";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
    dispatch(dispatchLoadingStatus(true));
    let formData = new FormData();
    let formData2 = new FormData();
    const access_id = localStorage.getItem("user_idd")
    formData.append("start", 0);
    formData.append("limit", 5);
    formData2.append("user_id", access_id)
    dispatch(accessDatadetails_API(formData2))
    dispatch(environmentalSectionlist_API(formData));
  }, []);

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <Loader />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Home Page Highlights List</h4>
                    </div>

                  </div>
                  <div className="iq-card-body">
                    <div className="table-responsive">
                      <div className="row justify-content-between">
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="user_list_datatable_info"
                            className="dataTables_filter"
                          >
                            <form className="mr-3 position-relative">
                              <div className="form-group mb-0">

                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <table
                        id="user-list-table"
                        className="table table-striped table-bordered mt-4"
                        role="grid"
                        aria-describedby="user-list-page-info"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Title
                              <i onClick={() => Sortt("Title")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Xnumber
                              <i onClick={() => Sortt22("Xnumber")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Description
                              <i onClick={() => Sortt("Description")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Date
                              <i onClick={() => Sortt22("created_at")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {datasrc &&
                            datasrc.length > 0 ? (
                            datasrc.map((i, index) => (
                              <tr>
                                <td>{index + 1}.</td>
                                <td>{i.Title}</td>
                                <td>{i.Xnumber}</td>
                                <td>{i.Description}</td>
                                <td>
                                  {i.created_at
                                    ? moment(i.created_at).format("MM/DD/YYYY")
                                    : ""}
                                </td>
                                <td>
                                  <div className="flex align-items-center list-user-action">
                                    {Login_Parse?.EnvironmentSelectionEdit == true ?
                                      <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => history("/HomePageHighlightsEdit", { state: i, })}><i className="ri-pencil-line point"></i></a>
                                      :
                                      <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => displaySwal()}><i className="ri-pencil-line point"></i></a>
                                    }
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : <tr className='bg-white border-0'> <td colSpan={4} className='border-0'> <div className='col-md-12 w-100 mt-4'><p className='text-center'>No Data Found</p> </div></td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <div className="row justify-content-between mt-3">
                      <div id="user-list-page-info" className="col-md-6">
                        {environmentalSectionReducer &&
                          environmentalSectionReducer.count ? (
                          <span>
                            Showing {page <= 1 ? 1 : (page - 1) * 5 + 1} to{" "}
                            {page * 5 <= environmentalSectionReducer &&
                              environmentalSectionReducer.count
                              ? page * 5
                              : environmentalSectionReducer &&
                              environmentalSectionReducer.count}{" "}
                            of{" "}
                            {environmentalSectionReducer
                              ? environmentalSectionReducer.count
                              : ""}{" "}
                            entries
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
