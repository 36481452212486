import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { dispatchLoadingStatus, productAdd_API, getAllCategoriesTitle_API, getfabricCategory_API, getDeals_API, getPriceRange_API, productEdit_API, productdetails_API, product_size_add_API, product_size_edit_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import swal from 'sweetalert';
import Select from 'react-select';
import Deleteicon from "../../Assets/Images/ic-delete.svg"
import Group57 from "../../Assets/Images/group-57.svg"

// Added by Parth Roka
function Add(props) {

    let productdetailsReducer = useSelector(state => state.globalReducer.productdetailsReducer);
    let productAddReducer = useSelector(state => state.globalReducer.productAddReducer);
    let productEditReducer = useSelector(state => state.globalReducer.productEditReducer);
    const [labelValue, setLabelValue] = useState(false);
    const [fields, setFields] = useState([{ colorName: '', colorCode: '', base64: null, id: "" }]);
    const [errors, setErrors] = useState([{ colorName: '', colorCode: '', base64: '' }]);

    const addField = () => {
        setFields([...fields, { colorName: '', colorCode: '', base64: null }]);
        setErrors([...errors, { colorName: '', colorCode: '', base64: '' }]);
    };

    const [remember, setRemember] = useState(false);
    const [propsValue, setPropsValue] = useState();
    const dispatch = useDispatch({});
    const [febricCategory, setFebricCategory] = useState();
    const [dealCategory, setDealCategory] = useState();
    const [productName, setProductName] = useState();
    const staticText = "$";
    const [price, setPrice] = useState("");
    const [sendFebric, setSendFebric] = useState();
    const [sendDeals, setSendDeals] = useState();
    const [sendDes, setSendDes] = useState();
    const [profuctError, setProductError] = useState(false);
    const [productError2, setProductError2] = useState("");
    const [priceError, setPriceError] = useState(false);
    const [priceError2, setPriceError2] = useState("");
    const [febricError, setFebricError] = useState(false);
    const [febricError2, setFebricError2] = useState("");
    const [dealError, setDealError] = useState(false);
    const [dealError2, setDealError2] = useState("");
    const [sendSize, setSize] = useState("");
    const [sendSize2, setSize2] = useState("");
    const [sizeError, setSizeError] = useState(false);
    const [sizeError2, setSizeError2] = useState("");
    const [sizeError3, setSizeError3] = useState(false);
    const [SizeErrorInput, setSizeErrorInput] = useState("");
    const [desError, setDesError] = useState(false);
    const [desError2, setDesError2] = useState("");
    let token = localStorage.getItem("token");
    let getfabricCategoryReducer = useSelector(state => state.globalReducer.getfabricCategoryReducer);
    let getDealsReducer = useSelector(state => state.globalReducer.getDealsReducer);
    let product_image_deleteReducer = useSelector(state => state.globalReducer.product_image_deleteReducer);
    let product_size_addReducer = useSelector(state => state.globalReducer.product_size_addReducer);
    let product_size_editReducer = useSelector(state => state.globalReducer.product_size_editReducer);
    const [val, setVal] = useState([{ colorName: "", colorCode: "", image: null }]);
    const [sendLink, setSendLink] = useState([{ Link: "" }]);
    const phoneRe = /^[0-9$]+$/;
    let nameRe2 = /^[a-zA-Z\s- ']*$/;

    useEffect(() => {
        if (productAddReducer) {
            if (productAddReducer.errorcode === 2) {
                swal({
                    text: productAddReducer.message,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            setRemember(true);
                            handleSubmit(true);
                        }
                        else {
                            setRemember(false);
                        }
                    });

            }
        }

    }, [productAddReducer])

    useEffect(() => {
        if (productEditReducer) {
            if (productEditReducer.errorcode === 2) {
                swal({
                    text: productEditReducer.message,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            setRemember(true);
                            handleSubmit(true);
                        }
                        else {
                            setRemember(false);
                        }
                    });

            }
        }

    }, [productEditReducer])

    const removeField = (index, length) => {
        if (length == 1) {
            setFields([])
        }

        const newFields = [...fields];
        setFields(newFields);
    };

    const removeImage = (index, field, msg) => {
        const newFields = [...fields];
        newFields[index]['base64'] = null;
        newFields[index]['image'] = null;
        newFields[index]['fileobj'] = null;
        setFields(newFields);

    };
    const handleInputChange = (index, key, value) => {
        // Simple validation for colorName and colorCode (non-empty, no spaces)
        const newErrors = [...errors];
        setErrors(newErrors);
        const newFields = [...fields];
        newFields[index][key] = value;
        setFields(newFields);
    };

    const handleFileChange = (index, file) => {
        // Validate image file format
        const allowedFormats = ['.png', '.jpg', '.jpeg'];
        const fileFormat = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

        const newErrors = [...errors];
        if (!allowedFormats.includes('.' + fileFormat.toLowerCase())) {
            newErrors[index]['base64'] = 'Invalid image format. Please select a .png, .jpg, or .jpeg file.';
            setErrors(newErrors);

            // Do not update state if validation fails
            return;
        }

        newErrors[index] = newErrors[index] || {};
        newErrors[index]['base64'] = '';

        setErrors(newErrors);

        const newFields = [...fields];
        newFields[index] = newFields[index] || {};
        newFields[index]['fileobj'] = file;

        getBase64(file, (base64Img) => {
            newFields[index]['base64'] = base64Img;
        });


        setFields(newFields);
    };

    function getBase64(file, callback) {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result.split(",")[1])
        }
        reader.readAsDataURL(file);
    }

    useEffect(() => {
        if (productdetailsReducer) {

            let data3 = productdetailsReducer.data[0].images.map(({ color_code, color_name, image, productimage_id }) => ({
                colorCode: color_code || '',
                colorName: color_name || '',
                image: image || null,
                productimage_id: productimage_id || ''
            }));

            // Check if totalImage has changed before updating state
            if (JSON.stringify(data3) !== JSON.stringify(fields)) {
                setFields(data3);
            }
            let data7 = productdetailsReducer && productdetailsReducer.data[0].amazon_link.split(',');
            let data4 = data7 && data7.map(i => ({
                Link: i || ''
            }));

            console.log("data4", data4 && data4);
            if (data4) {
                setSendLink(data4);

            }

        }
    }, [productdetailsReducer]);

    const handleLinkInputChange = (e, index) => {

        const { name, value } = e.target;
        const list = [...sendLink];

        list[index][name] = value;
        if (name === 'Link') {
            list[index]['Linkerr'] = value ? "" : "Please enter store link";

        }

        setSendLink(list);

    }

    const handleLinkRemove = (index) => {
        const list = [...sendLink];
        if (index > -1) {
            list.splice(index, 1);
        }
        setSendLink(list);
    }

    const handleLinkClick = () => {
        setSendLink([...sendLink, { Link: "" }]);
    }

    const hasValidationErrors = () => {
        return sendLink.some((link) => !link.Link);
    };
    useEffect(() => {
        if (val) {
            if (val.length == 0) {
                setVal([{ colorName: "", colorCode: "" }])
            }
        }
    }, [val])

    useEffect(() => {
        if (getfabricCategoryReducer) {
            if (getfabricCategoryReducer.errorcode == 0) {
                setFebricCategory(getfabricCategoryReducer.data);

            }
            else {
                setFebricCategory([]);

            }

        }

    }, [getfabricCategoryReducer])

    useEffect(() => {
        if (getDealsReducer) {
            if (getDealsReducer.errorcode == 0) {
                setDealCategory(getDealsReducer.data);

            }
            else {
                setDealCategory([]);

            }

        }

    }, [getDealsReducer])

    useEffect(() => {
        if (product_image_deleteReducer) {
            if (product_image_deleteReducer.errorcode == 0) {

                APICall()
            }


        }

    }, [product_image_deleteReducer])

    useEffect(() => {
        if (product_size_editReducer) {
            if (product_size_editReducer.errorcode == 0) {
                setSize2("");
                setSize("");
                setLabelValue(false);
                dispatch(product_size_add_API());
            }
        }

    }, [product_size_editReducer])

    useEffect(() => {
        if (productdetailsReducer) {
            let data = getfabricCategoryReducer ? getfabricCategoryReducer.data.filter((i) => i.fabricCategory_id == productdetailsReducer.data[0].fabricCategory_id) : "";

            let data2 = getDealsReducer ? getDealsReducer.data.filter((i) => i.deals_id == productdetailsReducer.data[0].deals_id) : "";
            setProductName(productdetailsReducer.data[0].product_name);
            setPrice(productdetailsReducer.data[0].price.replace('$', ''));
            setSendFebric([{ label: data ? data[0].type_of_category : "", value: data ? data[0].fabricCategory_id : "" }]);
            setSendDeals([{ label: data2 ? data2[0].type_of_price : "", value: data2 ? data2[0].deals_id : "" }]);
            setSendDes(productdetailsReducer.data[0].description);
            let data3 = productdetailsReducer.data[0].images.map(({ color_code, color_name }) => ({
                colorCode: color_code,
                colorName: color_name,
            }));
            setVal(data3 ? data3 : val)
            let data6 = productdetailsReducer ? productdetailsReducer.data[0].product_size : "";
            let data7 = data6 && data6.split(',').map(Number)
            let options = product_size_addReducer ? product_size_addReducer.data.filter(i => data7.includes(i.product_size_id)) : ""
            setRemember(productdetailsReducer.data[0].features == null ? false : true);
            setSize(options && options.map((i) => ({ label: i.product_size, value: i.product_size_id })))

        }


    }, [productdetailsReducer])

    function handleMessage() {
        swal({
            text: "Are you sure you want to leave this page?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    window.location.href = window.location.origin + "/ProductList";

                }
            });

    }

    useEffect(() => {
        if (!token) {

            if (!window.location.href.includes("/Login")) {
                window.location.href = window.location.origin + "/Login";
            }
        }

        dispatch(getAllCategoriesTitle_API());
        dispatch(getfabricCategory_API());
        dispatch(getDeals_API());
        dispatch(getPriceRange_API());
        dispatch(product_size_add_API());

    }, [])
    useEffect(() => {
        if (props.data) {
            let formData = new FormData();
            setPropsValue(props.data);

            formData.append('product_id', props ? props.data.product_id : "");
            dispatch(dispatchLoadingStatus(true));
            dispatch(productdetails_API(formData));
        }
    }, [props])

    function APICall() {
        let formData = new FormData();

        formData.append('product_id', props ? props.data.product_id : "");
        dispatch(dispatchLoadingStatus(true));
        dispatch(productdetails_API(formData));
    }

    function handleAddSize() {

        if (sendSize2) {
            setSizeError3(false);
            setSizeErrorInput("");
        }
        else {
            setSizeError3(true);
            setSizeErrorInput("Please enter size");
        }

        if (sizeError3 == false) {

            if (sendSize2) {
                let formData = new FormData();

                formData.append('product_size', sendSize2);
                dispatch(dispatchLoadingStatus(true));
                dispatch(product_size_edit_API(formData));
            }
        }


    }

    function handleProductName(e) {

        let data = e.target.value
        let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z- ']/g, '');
        setProductName(data.replace(/[^a-zA-Z- ']/g, ''))

        if (data2) {

            if (data2.length >= 2) {
                setProductError(false);
                if (!nameRe2.test(data)) {
                    setProductError(true);
                    setProductError2("Please enter a valid  product name");


                }
                else {
                    setProductError(false);
                    setProductError2("");

                }
            }
            else {
                setProductError(true);
                setProductError2("Product name must be  2 to 100 characters long");

            }
        }
        else {
            setProductError(true);
            setProductError2("Please enter product name");
        }

    }

    function handleDeal(e) {
        setSendDeals(e);
        if (e) {
            setDealError(false);
            setDealError2("")
        }
        else {
            setDealError(true);

        }
    }

    function handleSize(e) {

        const selectedValues = Array.isArray(e) ? e : [e];
        const isAddSelected = selectedValues.some(item => item.value === 'Add');
        setLabelValue(isAddSelected);
        if (isAddSelected) {
            setSizeError2("");

            setSize([{ label: 'Add Size', value: 'Add' }]);
            setSizeError(false);
            return;
        }
        else {
            setSize(e);
            setSizeError2("")
        }

        if (e && e.length > 0) {

            setSizeError(false);
        }
        else {
            setSizeError(true);

        }
    }

    function handleSize2(e) {
        let data = e.target.value.trim() == "" ? "" : e.target.value;
        setSize2(data)

        if (data) {
            setSizeError3(false);
            setSizeErrorInput("");
        }
        else {
            setSizeError3(true);
            setSizeErrorInput("Please enter size");


        }
    }


    function handleDes(e) {
        let data = e.target.value;
        setSendDes(e.target.value.trim() == "" ? "" : e.target.value);

        if (data) {
            setDesError(false);
            setDesError2("");
        }
        else {
            setDesError(true);
            setDesError2("Please enter description");


        }
    }

    function handleFebric(e) {
        setSendFebric(e);
        if (e) {
            setFebricError(false);
            setFebricError2("")
        }
        else {
            setFebricError(true);

        }
    }

    function handlePrice(e) {
        let data = e.target.value;
        let data2 = e.target.value.trim() == "" ? "" : e.target.value;
        let data3 = phoneRe.test(data2) ? data2 : "";

        setPrice(data3.replace(/^\$/, ''));

        if (data) {


            if (!phoneRe.test(data)) {
                setPriceError(true);
                setPriceError2("Please enter valid price");


            }
            else {
                setPriceError(false);
                setPriceError2("");

            }

        }
        else {
            setPriceError(true);
            setPriceError2("Please enter price");

        }
    }

    // Key Press Functionality Added by Parth Roka
    function handleSubmit(keyPer) {
        let isAnyObjectFilled = false;
        fields && fields.forEach(item => {
            // Check if any of the properties is filled
            if (item.base64 || item.fileObj || item.image) {
                isAnyObjectFilled = true;
            }
        });

        const newErrors = fields.map((field, index) => {
            const errorsForField = {};
            if (field.base64 || field.fileobj || field.image) {
                errorsForField['base64'] = '';

            }
            else {
                errorsForField['base64'] = 'Please select an image';
            }

            return errorsForField;
        });

        setErrors(newErrors);
        const list = [...sendLink];
        const formError = hasValidationErrors()
            ? 'Please fix validation errors before submitting.'
            : '';

        list.forEach((link) => {
            link.Linkerr = link.Link ? '' : 'Please enter store link';
        });

        setSendLink(list);

        if (productName) {
            if (productName.length >= 2) {
                if (!nameRe2.test(productName)) {
                    setProductError2("Please enter valid product name");
                } else {
                    setProductError2("");
                }
            } else {
                setProductError2("Product name must be 2 to 100 characters long");
            }
        } else {
            setProductError2("Please enter product name");
        }

        if (price) {

            setPriceError2("");

            if (!phoneRe.test(price)) {
                setPriceError2("Please enter valid price");

            }
            else {
                setPriceError2("");

            }


        }
        else {
            setPriceError2("Please enter price");
        }

        if (sendDeals) {
            setDealError2("");
        }
        else {
            setDealError2("Please select deals");

        }

        if (sendFebric) {
            setFebricError2("");
        }
        else {
            setFebricError2("Please select type of fabric");

        }

        if (sendDes) {
            setDesError2("");
        }
        else {
            setDesError2("Please enter description");

        }

        if (sendDeals) {
            setFebricError2("");
        }
        else {
            setFebricError2("Please select type of fabric");

        }

        if (sendSize) {
            setSizeError2("");
        }
        else {
            if (sendSize.value == "") {
                setSizeError2("");

            }
            else {
                setSizeError2("Please select size");

            }

        }


        if (!profuctError && !priceError && !febricError && !dealError && !desError &&

            productName && price && sendDeals && sendFebric && !formError && isAnyObjectFilled) {

            if (labelValue == true) {
                if (!sizeError3 && sendSize2) {
                    APICall2(keyPer);
                }
            }
            else {
                if (!sizeError && sendSize) {
                    APICall2(keyPer);

                }

            }

        }
    }

    // Main API Call Added by Parth Roka
    function APICall2(keyPer) {
        if (propsValue) {

            if (propsValue.fabricCategory_id) {

                let dataLink = sendLink && sendLink.map((i) => i.Link)
                let dataMain = sendSize ? sendSize.map((i) => i.value) : ""
                let sendAPI2 = {
                    "description": sendDes,
                    "product_name": productName,
                    "price": staticText + price,
                    "amazon_link": dataLink && dataLink.join(','),
                    "deals_id": sendDeals ? sendDeals.value : "",
                    "fabricCategory_id": sendFebric ? sendFebric.value : "",
                    "colorInfo": fields ? fields : "",
                    "product_size": dataMain && dataMain.join(','),
                    "features": remember && remember == true ? 1 : "",
                    "featurePermissions": keyPer == true ? 1 : "",
                    "product_id": propsValue ? propsValue.product_id : ""
                }


                dispatch(productEdit_API(sendAPI2));
                dispatch(dispatchLoadingStatus(true));

            }
            else {
                let dataLink = sendLink && sendLink.map((i) => i.Link);
                let dataMain = sendSize ? sendSize.map((i) => i.value) : ""

                let sendAPI = {
                    "description": sendDes,
                    "product_name": productName,
                    "price": staticText + price,
                    "amazon_link": dataLink && dataLink.join(','),
                    "deals_id": sendDeals ? sendDeals.value : "",
                    "fabricCategory_id": sendFebric ? sendFebric.value : "",
                    "colorInfo": fields ? fields : "",
                    "product_size": dataMain && dataMain.join(','),
                    "features": remember && remember == true ? 1 : "",
                    "featurePermissions": keyPer == true ? 1 : ""
                }
                dispatch(productAdd_API(sendAPI));
                dispatch(dispatchLoadingStatus(true));

            }
        }
        else {
            let dataLink = sendLink && sendLink.map((i) => i.Link);
            let dataMain = sendSize ? sendSize.map((i) => i.value) : ""

            let sendAPI = {
                "description": sendDes,
                "product_name": productName,
                "price": staticText + price,
                "amazon_link": dataLink && dataLink.join(','),
                "deals_id": sendDeals ? sendDeals.value : "",
                "fabricCategory_id": sendFebric ? sendFebric.value : "",
                "colorInfo": fields ? fields : "",
                "product_size": dataMain && dataMain.join(','),
                "features": remember && remember == true ? 1 : "",
                "featurePermissions": keyPer == true ? 1 : ""
            }
            dispatch(productAdd_API(sendAPI));
            dispatch(dispatchLoadingStatus(true));

        }
    }

    return (
        <>
            <div className="wrapper">
                <Loader />
                <Sidebar />


                <div id="content-page" className="content-page">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="iq-edit-list-data">
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                                            <div className="iq-card">
                                                <div className="iq-card-header d-flex justify-content-between">
                                                    <div className="iq-header-title">
                                                        <h4 className="card-title"> {propsValue ? propsValue.fabricCategory_id ? "Product Edit" : "Product Add" : "Product Add"}</h4>
                                                    </div>

                                                    <ul class="d-flex mt-3">
                                                        <li class="breadcrumb-item"><a href="/ProductList">Product List</a></li>
                                                        <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.fabricCategory_id ? "Product Edit" : "Product Add" : "Product Add"}</li>
                                                    </ul>
                                                </div>
                                                <div className="iq-card-body">

                                                    <div className="row">
                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Product Name <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" maxLength={100} placeholder='Enter  Product Name' value={productName} onChange={(e) => handleProductName(e)} minLength={3} />
                                                            <p className='errorr'>{productError2}</p>
                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Price <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" maxLength={12} value={staticText + price} placeholder='Enter Price' onChange={(e) => handlePrice(e)} />
                                                            <p className='errorr'>{priceError2}</p>
                                                        </div>


                                                        <div className="form-group col-sm-6 ">
                                                            <label htmlFor="fname">Type of Fabric <span className="text-danger">*</span></label>
                                                            <Select
                                                                className='point'

                                                                options={febricCategory ? febricCategory.map((dataItem) => ({
                                                                    value: dataItem.fabricCategory_id,
                                                                    label: dataItem.type_of_category,
                                                                })) : [{ label: "Select", value: "" }]}
                                                                placeholder="Select"
                                                                isClearable={false}
                                                                value={sendFebric}

                                                                onChange={(e) => handleFebric(e)}
                                                            />
                                                            <p className='errorr'>{febricError2}</p>
                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Deals <span className="text-danger">*</span></label>
                                                            <Select
                                                                options={dealCategory ? dealCategory.map((dataItem) => ({
                                                                    value: dataItem.deals_id,
                                                                    label: dataItem.type_of_price,
                                                                })) : [{ value: "", label: "Select" }]}
                                                                placeholder="Select"
                                                                isClearable={false}
                                                                value={sendDeals}
                                                                onChange={(e) => handleDeal(e)}
                                                            />
                                                            <p className='errorr'>{dealError2}</p>

                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Size <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti={labelValue && labelValue === true ? false : true}
                                                                options={product_size_addReducer ? product_size_addReducer.data.map((dataItem) => ({
                                                                    value: dataItem.product_size_id,
                                                                    label: dataItem.product_size,
                                                                })).concat([{ label: 'Add Size', value: 'Add' }]) : [{ value: "", label: "Select" }]}
                                                                placeholder="Select"
                                                                isClearable={false}
                                                                value={sendSize}
                                                                onChange={(e) => handleSize(e)}
                                                            />
                                                            <p className='errorr'>{sizeError2}</p>

                                                            {labelValue && labelValue === true ?
                                                                <>
                                                                    <div className='my-3'>
                                                                        <input type="text" className="form-control" value={sendSize2} maxLength={15} placeholder='Enter Size' onChange={(e) => handleSize2(e)} />
                                                                        <p className='errorr'>{SizeErrorInput}</p>
                                                                    </div>
                                                                    <div class="btn-div-more">
                                                                        <button className="btn btn-primary d-block" onClick={() => handleAddSize()}  >
                                                                            Add Size
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                : ""}

                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Store Link <span className="text-danger">*</span></label>

                                                            {sendLink.map((i, index) => {
                                                                return (
                                                                    <>
                                                                        <div className='mb-3'>
                                                                            <div className='d-flex '>
                                                                                <input type="text" style={{ width: '86%' }} className="form-control " placeholder='Enter Store Link' name='Link' value={i.Link} onChange={(e) => { handleLinkInputChange(e, index) }} maxLength={2048} />


                                                                                {sendLink.length > 1 &&

                                                                                    <img className='delete-icn delete-icons' style={{ cursor: 'pointer' }}
                                                                                        src={Deleteicon}
                                                                                        alt="ic-delete"
                                                                                        onClick={() => handleLinkRemove(index)}

                                                                                    />

                                                                                }


                                                                                {sendLink.length - 1 === index &&


                                                                                    <img className='ml-1 delete-icn' onClick={handleLinkClick} style={{ cursor: 'pointer' }}
                                                                                        src={Group57}
                                                                                        alt="ic-delete"
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            {i.Linkerr && <p className='errorr d-block'>{i.Linkerr}</p>}
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <div className="form-group col-sm-14">
                                                        <label>Description <span className="text-danger">*</span></label>
                                                        <textarea className="form-control" name="address" onChange={handleDes} value={sendDes} rows="5" maxLength={250} placeholder='Enter Description' />
                                                        {desError2 && <p className='errorr'>{desError2}</p>}
                                                    </div>
                                                    {fields.map((field, index) => (
                                                        <div className='row'>
                                                            {field.image || field.fileobj ? <div className='col-md-2'>

                                                                {field.fileobj ? (
                                                                    <div className='profile-image mb-3 '>

                                                                        <img className='avatar-130 img-fluid' src={URL.createObjectURL(field.fileobj)} alt="Selected" style={{ maxWidth: '100px' }} />
                                                                        <a className='cross-btn' onClick={() => removeImage(index, field, "OBJ")} style={{ cursor: 'pointer' }} >
                                                                            <i className='fa fa-close'></i>
                                                                        </a>
                                                                    </div>
                                                                ) : field.image ? (
                                                                    <div className='profile-image mb-3 '>
                                                                        <img className='avatar-130 img-fluid' src={field.image} alt="Selected" style={{ maxWidth: '100px' }} />
                                                                        <a className='cross-btn' onClick={() => removeImage(index, field)} style={{ cursor: 'pointer' }}>
                                                                            <i className='fa fa-close'></i>
                                                                        </a>
                                                                    </div>
                                                                )
                                                                    :

                                                                    "sdvsfs"}

                                                            </div>

                                                                : <div className="col-md-3">
                                                                    <div className="">
                                                                        <label htmlFor="institute" className="form-label">Select Product Image <span className="text-danger">*</span></label>
                                                                        <input
                                                                            className='file-input'
                                                                            name='base64'
                                                                            type="file"
                                                                            accept=".png, .jpg, .jpeg"
                                                                            onChange={(e) => handleFileChange(index, e.target.files[0])}
                                                                        />

                                                                    </div>
                                                                    {errors[index] && errors[index]['base64'] && <div className='error-12 errorr'>{errors[index]['base64']}</div>}

                                                                </div>}



                                                            <div className="col-md-2">
                                                                <div className="mb-3">
                                                                    <label htmlFor="institute" className="form-label">Color Name </label>
                                                                    <input
                                                                        className='form-control'
                                                                        type="text"
                                                                        placeholder="Color Name"
                                                                        value={field.colorName || ''}
                                                                        onChange={(e) => handleInputChange(index, 'colorName', e.target.value)}
                                                                    />
                                                                    {errors[index] && errors[index]['colorName'] && <div className='errorr'>{errors[index]['colorName']}</div>}

                                                                </div>
                                                            </div>


                                                            <div className="col-md-2">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Color Code </label>

                                                                    <input
                                                                        className='form-control'
                                                                        type="text"
                                                                        placeholder="Color Code"
                                                                        value={field.colorCode || ''}
                                                                        onChange={(e) => handleInputChange(index, 'colorCode', e.target.value)}
                                                                    />
                                                                    {errors[index] && errors[index]['colorCode'] && <div className='errorr'>{errors[index]['colorCode']}</div>}

                                                                </div>
                                                            </div>
                                                            {index == 0 &&
                                                                <div className="mt30 col-md-2">


                                                                    <button type="button" className='btn w-100 btn-primary' onClick={addField}>
                                                                        <i class="ri-add-line"></i> Add More
                                                                    </button>

                                                                </div>
                                                            }


                                                            {/* {fields.length > 1 && */}
                                                            {fields.length > 1 && index !== 0 && (


                                                                <div className="mt30 col-md-2">
                                                                    <button className="btn  w-100 btn-danger" type="button" onClick={() => removeField(index, fields.length)}>
                                                                        <i class="ri-close-line"></i>   Remove
                                                                    </button>

                                                                </div>
                                                            )}

                                                        </div>
                                                    ))
                                                        // : null

                                                    } <br /> <br />


                                                    <div className="form-group col-sm-14">
                                                        <label ></label>

                                                        {
                                                            remember ?
                                                                <img onClick={() => setRemember(!remember)} className="form-check-input" src="images/checked.svg" style={{ height: 15, width: 15, marginLeft: '0', cursor: 'pointer' }} /> :
                                                                <img onClick={() => setRemember(!remember)} className="form-check-input" src="images/checkbox-empty.svg" style={{ height: 15, width: 15, marginLeft: '0', cursor: 'pointer' }} />
                                                        }
                                                        <label onClick={() => setRemember(!remember)} style={{ marginLeft: '20px' }}>Marked product featured as home page?</label>
                                                    </div>

                                                    <button className="btn iq-bg-danger mr-2" onClick={() => handleMessage()}>Cancel</button>
                                                    <button className="btn btn-primary" onClick={() => handleSubmit()} >Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Add