import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import {
  dispatchLoadingStatus,
  passwordChange_API,
} from "../../API/Action/globalAction";

// Added by Mansi Vaghela
function ResetPassword() {
  const [Password, setPassword] = useState("");
  const [PasswordError, setPasswordError] = useState(false);
  const [ConformPassword, setConformPassword] = useState("");
  const [ConformPasswordError, setConformPasswordError] = useState(false);
  const [BothPasswordError, setBothPasswordError] = useState(false);
  const [PasswordError22, setPasswordError22] = useState(false);
  const [ConformPasswordError22, setConformPasswordError22] = useState(false);
  const [eyes, setEyes] = useState(false);
  const [eyes22, setEyes22] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch({});
  let history = useNavigate();


  function handlePassword(e) {
    if (e) {
      setPassword(e.target.value);
      setPasswordError(false);
    } else {
      setPassword("");
      setPasswordError(true);
    }
  }

  function handleConformPassword(e) {
    if (e) {
      setConformPassword(e.target.value);
      setConformPasswordError(false);
    } else {
      setConformPassword("");
      setConformPasswordError(true);
    }
  }

  const allDeatils = () => {
    if (Password) {
      if (Password.length >= 6 && Password.length <= 16) {
        setPasswordError22(false);
        setPasswordError(false);
      } else {
        setPasswordError22(true);
        setPasswordError(false);
      }
    } else {
      setPasswordError(true);
      setPasswordError22(false);
    }

    if (ConformPassword) {
      if (ConformPassword.length >= 6 && ConformPassword.length <= 16) {
        setConformPasswordError22(false);
        setConformPasswordError(false);
      } else {
        setConformPasswordError22(true);
        setConformPasswordError(false);
      }
    } else {
      setConformPasswordError(true);
      setConformPasswordError22(false);
    }

    if (
      Password.length >= 6 &&
      Password.length <= 16 &&
      ConformPassword.length >= 6 &&
      ConformPassword.length <= 16
    ) {
      if (Password == ConformPassword) {
        setBothPasswordError(false);
        let setpsw = localStorage.setItem("password", Password);
        console.log("psw", setpsw);

        let user_id = localStorage.getItem("user_id");
        let psww = localStorage.getItem("password", setpsw);

        let formData = new FormData();
        formData.append("user_id", user_id);
        formData.append("password", psww);
        dispatch(dispatchLoadingStatus(true));
        dispatch(passwordChange_API(formData));
      } else {
        setBothPasswordError(true);
      }
    }
  };

  const passwordChangeReducer = useSelector(
    (state) => state.globalReducer.passwordChangeReducer
  );

  useEffect(() => {
    if (localStorage.getItem("noPage")) {
      history('/Login')
    }
    localStorage.removeItem("resendValue");
    localStorage.removeItem("emailAdmin");
    localStorage.removeItem('myotp');
  }, []);

  let emailAdmin = localStorage.getItem("emailAdmin");

  useEffect(() => {
    if (passwordChangeReducer) {
      history("/Login");
    }
  }, [passwordChangeReducer]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      debugger;
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [location]);

  useEffect(() => {
    if (emailAdmin) {
      console.log("emailAdmin", emailAdmin);
      if (!window.location.href.includes("ResetPassword")) {
        window.location.href = window.location.origin + "/ResetPassword";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);

  return (
    <section className="main-forget-pw sign-in-page bg-white">
      <div className="login-container">
        <Loader />

        <form class="login-form" onSubmit={(e) => e.preventDefault()}>
          <h1>Reset Password</h1>
          <p>Please Fill the details to forgot your password</p>

          <div class="input-group">
            <label className="form-label">Password</label>
            <input
              type={eyes ? "text" : "password"}

              placeholder="Enter Password"
              value={Password}
              onChange={(e) => handlePassword(e)}
              maxLength={16}
            />
            {PasswordError && (
              <span style={{ color: "#BE312E" }}>Please enter password</span>
            )}
            {PasswordError22 && (
              <span style={{ color: "#BE312E" }}>
                Please enter a password that contains a minimum of 6 characters
                and a maximum of 16 characters
              </span>
            )}
          </div>
          <div class="input-group">
            <label class="label">Confirm Password</label>
            <input

              type={eyes22 ? "text" : "password"}

              placeholder="Enter Confirm Password"
              value={ConformPassword}
              onChange={(e) => handleConformPassword(e)}
              maxLength={16}
              id="email" name="email" />
            {ConformPasswordError && (
              <span style={{ color: "#BE312E" }}>
                Please enter confirm password
              </span>
            )}
            {ConformPasswordError22 == true
              ? ConformPasswordError22 && (
                <span style={{ color: "#BE312E" }}>
                  Please enter a password that contains a minimum of 6 characters and a maximum of 16 characters

                </span>
              )
              : BothPasswordError && (
                <span style={{ color: "#BE312E" }}>
                  Password and Confirm Password does not match
                </span>
              )}
          </div>
          <button type="submit"
            onClick={allDeatils} class="btn btn-primary float-right">Submit</button>
        </form>
      </div>
    </section>
  );
}

export default ResetPassword;
