import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";
import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";
import {
  citieslist_API,
  contactAdd_API,
  contactEdit_API,
  countrylist_API,
  dispatchLoadingStatus,
  getstatecityAPI,
  ourTeamAdd_API,
  ourTeamEdit_API,
  pagesAdd_API,
  pagesEdit_API,
} from "../../API/Action/globalAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import { ourTeamAdd_URL, ourTeamEdit_URL } from "../../OtherFiles/Constant";
import Select from "react-select";


const formatPhoneNumber = (number) => {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1,})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const countryCode = match[1] ? `+${match[1]}` : '';
    return `${countryCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return number;
};


// Added by Parth Roka
function Add(props) {

  const countrylistReg = useSelector(state => state.globalReducer.countrylistReg);
  const getstatecityReg = useSelector(state => state.globalReducer.getstatecityReg);
  const citieslistReg = useSelector(state => state.globalReducer.citieslistReg);
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [SendCountry, setSendCountry] = useState([{ label: "Country", value: "" }]);
  const [SendState, setSendState] = useState([{ label: "State", value: "" }]);
  const [SendCity, setSendCity] = useState([{ label: "City", value: "" }]);
  const [SendCountryErr, setSendCountryErr] = useState("");
  const [SendStateErr, setSendStateErr] = useState("");
  const [SendCityErr, setSendCityErr] = useState("");
  const [SendCountryT, setSendCountryT] = useState(false);
  const [SendStateT, setSendStateT] = useState(false);
  const [SendCityT, setSendCityT] = useState(false);
  const [propsValue, setPropsValue] = useState();
  const dispatch = useDispatch({});
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [addressError2, setAddressError2] = useState();
  const [addressError, setAddressError] = useState(false);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError2, setDescriptionError2] = useState();
  const [descriptionError, setDescriptionError] = useState(false);

  const [titleError, setTitleError] = useState(false);
  const [titleError2, setTitleError2] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailError2, setEmailError2] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneError2, setPhoneError2] = useState("");
  const [pincode, setpincode] = useState('');
  const [pincodeer, setpincodeer] = useState('');
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {

      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
    dispatch(dispatchLoadingStatus(true))
    dispatch(countrylist_API());

  }, [])

  useEffect(() => {
    if (props.data) {
      console.log("props.data", props.data);
      setPropsValue(props.data);
      setTitle(props.data.title);
      setAddress(props.data.address_1);
      setEmail(props.data.email_id);
      setPhone(props.data.phone_1);
      setpincode(props.data.zip_code);
      setDescription(props.data.description);

      setSendCountry([{ label: props.data.country_1, value: props.data.country_id }]);
      setSendState([{ label: props.data.state_1, value: props.data.state_id }]);
      setSendCity([{ label: props.data.city_1, value: props.data.city_id }]);

      if (props.data.country_id) {
        dispatch(countrylist_API());
      }

      if (props.data.country_id) {
        let formData = new FormData();
        formData.append("country_id", props.data.country_id);
        dispatch(getstatecityAPI(formData));
      }
      if (props.data.state_id && props.data.country_id) {
        let formData = new FormData();
        formData.append("country_id", props.data.country_id);
        formData.append("state_id", props.data.state_id);
        dispatch(citieslist_API(formData))
      }


    }
  }, [props]);
  function handleCountry(e) {
    console.log(e, "ee");
    if (e.value) {
      let formData = new FormData();
      formData.append("country_id", e.value);
      dispatch(getstatecityAPI(formData));
      setSendState([{ label: "State", value: "" }])
      setSendCity([{ label: "City", value: "" }])
      setCityList([]);
      setSendCountry(e);
      setSendCountryErr("");
      setSendCountryT(false);

    }
    else {
      setSendCountry([{ label: "Country", value: "" }])
      setSendState([{ label: "State", value: "" }])
      setSendCity([{ label: "City", value: "" }])
      setCityList([]);
      setStateList([]);
      setSendCountryErr("Please select country");
      setSendCountryT(true);

    }


  }

  function handleState(e) {
    console.log("estate", e);
    if (e.value) {
      let formData = new FormData();
      formData.append("country_id", SendCountry && SendCountry.value || SendCountry[0].value);
      formData.append("state_id", e.value);
      dispatch(citieslist_API(formData))
      setSendCity([{ label: "City", value: "" }])
      setSendState(e);
      setSendStateErr("");
      setSendStateT(false);
    }
    else {
      setSendState([{ label: "State", value: "" }])
      setSendCity([{ label: "City", value: "" }])
      setCityList([]);
      setSendStateErr("Please select country");
      setSendStateT(true);

    }
  }

  function handleCity(e) {
    if (e.value) {
      setSendCity(e);
      setSendCityErr("");
      setSendCityT(false);
    }
    else {
      setSendCity([{ label: "City", value: "" }])
      setSendCityErr("Please select city");
      setSendCityT(true);
    }

  }
  useEffect(() => {

    if (countrylistReg) {
      console.log("countrylistReg", countrylistReg);
      setCountryList(countrylistReg.data)

    }
    else {
      setCountryList([])
    }
  }, [countrylistReg])

  useEffect(() => {

    if (getstatecityReg) {
      console.log("getstatecityReg", getstatecityReg);
      setStateList(getstatecityReg.data);

    }
    else {
      setStateList([]);
    }
  }, [getstatecityReg])

  useEffect(() => {

    if (citieslistReg) {
      console.log("citieslistReg", citieslistReg);
      setCityList(citieslistReg.data);

    }
    else {
      setCityList([])
    }
  }, [citieslistReg])



  function isValidEmail(email) {
    return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
  }
  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/LocationList";
      }
    });
  }

  let nameRe = /^[a-zA-Z\s]*$/;

  const [oldValue, setOldvalue] = useState("");

  function PhoneFormating(newValue) {
    if (!oldValue.includes("(") && oldValue.length >= 10 && newValue.length != oldValue.length) {
      setOldvalue("");
      return "";
    }

    if (oldValue.length > newValue.length) {
      setOldvalue(newValue);
      return newValue;
    }

    var newText = newValue;
    if (newText.length == 1) newText = "(" + newText;
    if (oldValue.length == 4) newText = oldValue + ") " + newText[newText.length - 1];
    if (oldValue.length == 9) newText = oldValue + "-" + newText[newText.length - 1];
    setOldvalue(newText);
    return newText;
  }
  const handlePhone = (e) => {

    // const inputValue = e.target.value;
      

    // const formattedNumber = inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '+1 ($1) $2 $3');
    // setPhone(formattedNumber);


    // const re = /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/;

    
    // if (e.target.value === '' || re.test(e.target.value)) {
    //   setPhone(e.target.value.replace(/(\d{3})(\d{3})(\d{4})/, '+1 ($1) $2 $3'))
    //   setPhoneError2('');
    //   setPhoneError(false)


    // } else {
    //   setPhoneError(true)

    // }





    let value = e.target.value.replace(/[\(\)\-\s]/g, "");
    const phoneNumber = value.slice(2);

    let regx2 = /^(\d)(?!\1+$)\d{10}$/;
    if (phoneNumber) {
      if (phoneNumber == 5555555555) {
      } else {
        if (/^\d{10}$/.test(phoneNumber) && new Set(phoneNumber).size === 1) {
          setPhoneError2("Please enter valid phone");
          setPhoneError(true);
          return;
        }
      }
    }
    if( value == "+"){
      setPhoneError2("");
      setPhone("")
      setPhoneError(false)
      return
    }

    if (phoneNumber.match(regx2)) {
      setPhoneError2("");
      setEmailError("")
      setPhoneError(false)
    }
     else {
      setPhoneError2("Please enter valid number");
      setPhoneError(true);

    }


    var reg = /^[0-9+()\-\s]+$/;

    if (e.target.value.trim().match(reg)) {
      if (e.target.value.replace("+1", "").length == 10 && !e.target.value.includes("(") && !e.target.value.includes(")")) {
        if (e.target.value.includes("+1")) {
          const formattedNumber = formatPhoneNumber(e.target.value);
          setPhone(formattedNumber);
          setPhoneError2("");
          setPhoneError(false)
          return;
        } else {
          const formattedNumber = formatPhoneNumber("+1" + e.target.value);
          setPhone(formattedNumber);
          setPhoneError2("");
          setPhoneError(false)
          return;
        }
      } else {
        setPhoneError2("Please enter phone");
        setPhoneError(true);

      }
    }

    if (e.target.value.trim().match(reg)) if (e.target.value.trim() != "+") setPhone("+1" + PhoneFormating(e.target.value.trim().replace("+1", ""), setPhoneError2("")));

    if (e.target.value.trim().length > 0) {
      if (e.target.value.trim().length > 0) {
        if (e.target.value.trim().length == 16) {
          setPhoneError2("");
          setPhoneError(false)
        } else {
          // setMobileError("Please provide a complete phone number with different digits");
          // setEmailError("Please enter email id");
        }

        // setMobileError("")
        // setEmailError("")
      } else {
        setPhoneError2("Please enter phone");
        setPhoneError(true);

      }
 
    } 
    else {
    
      setPhoneError2("Please enter phone");
      setPhoneError(true);

    }




  

}

  function handleAdd(e) {
    setAddress(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      setAddressError(false);
      setAddressError2("");
    } else {
      setAddressError(true);
      setAddressError2("Please enter address");
    }
  }

  function handleDes(e) {
    setDescription(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      setDescriptionError(false);
      setDescriptionError2("");
    } else {
      setDescriptionError(true);
      setDescriptionError2("Please enter description");
    }
  }
  function handleEmail(e) {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      if (!isValidEmail(e.target.value)) {
        setEmailError(true);
        setEmailError2("Please enter valid email");
      } else {
        setEmailError(false);

        setEmailError2("");
      }
    } else {
      setEmailError(true);
      setEmailError2("Please enter email");
    }
  }

  function handleTitle(e) {
    setTitle(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      if (e.target.value.length >= 2) {
        setTitleError(false);
        setTitleError2("");

        if (!nameRe.test(e.target.value)) {
          setTitleError(true);
          setTitleError2("Please enter valid title");
        } else {
          setTitleError(false);
          setTitleError2("");
        }
      } else {
        setTitleError(true);
        setTitleError2("Please enter valid title");
      }
    } else {
      setTitleError(true);
      setTitleError2("Please enter title");
    }
  }



  function handleSubmit() {

    if (SendCountry && SendCountry.value || SendCountry[0].value) {
      setSendCountryErr("");
    }
    else {
      setSendCountryErr("Please select country");

    }

    if (SendState && SendState.value || SendState[0].value) {
      setSendStateErr("");
    }
    else {
      setSendStateErr("Please select state");

    }

    if (SendCity && SendCity.value || SendCity[0].value) {
      setSendCityErr("");
    }
    else {
      setSendCityErr("Please select city");

    }
    if (pincode) {
      setpincodeer('');

    }
    else {
      setpincodeer('Please enter zipcode');

    }
    if (address) {
      if (address.length >= 5) {
        setAddressError2("");

      }
      else{
        setAddressError2("Please enter valid address");

      }
      
    } else {
      setAddressError2("Please enter address");
    }

    if (description) {
      if (description.length >= 5) {
      setDescriptionError2("");

      }
      else{

        setDescriptionError2("Please enter valid description");
      

      }
    } else {
      setDescriptionError2("Please enter description");
    }

    if (title) {
      if (title.length >= 2) {
        setTitleError2("");

        if (!nameRe.test(title)) {
          setTitleError2("Please enter valid title");
        } else {
          setTitleError(false);
          setTitleError2("");
        }
      } else {
        setTitleError2("Please enter valid title");
      }
    } else {
      setTitleError2("Please enter title");
    }

    if (email) {
      if (!isValidEmail(email)) {
        setEmailError2("Please enter valid email");
      } else {
        setEmailError2("");
      }
    } else {
      setEmailError2("Please enter email");
    }

    if (phone) {
      setPhoneError2("");

      if(phone.length == 16){
      setPhoneError2("");

      }
      else{
        setPhoneError2("Please enter valid phone");

      }
    } else {
      setPhoneError2("Please enter phone");
    }
    let stateCh = SendCountry.value;
    let stateCh2 = SendCountry && SendCountry.length > 0 ? SendCountry[0].value : "";
    let stateCh3 = SendState.value;
    let stateCh4 = SendState && SendState.length > 0 ? SendState[0].value : "";
    let stateCh5 = SendCity.value;
    let stateCh6 = SendCity && SendCity.length > 0 ? SendCity[0].value : "";

    if (
      titleError == false && emailError == false && phoneError == false && addressError == false && descriptionError == false && SendCountryT == false && SendStateT == false
      && SendCityT == false && email && title && phone && pincode && description &&
      address
    ) {
      if (stateCh2 || stateCh && stateCh3 || stateCh4 && stateCh5 || stateCh6) {
        MainFun();
      }

    }

  }

  const NumValid = (e) => {
    const re = /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setpincode(e.target.value)
      setpincodeer('');
      setAddressError2(false);
    } else {
      setAddressError2(true);
      setpincodeer('Please enter zipcode');


    }
  }
  function MainFun() {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("email_id", email);
    formData.append("phone_1", phone);
    formData.append("address_1", address);
    formData.append("zip_code", pincode);
    formData.append("description", description);
    
    formData.append("country_1", SendCountry && SendCountry.length > 0 ? SendCountry[0].value : SendCountry.value);
    formData.append("state_1", SendState && SendState.length > 0 ? SendState[0].value : SendState.value);
    formData.append("city_1", SendCity && SendCity.length > 0 ? SendCity[0].value : SendCity.value);

    let data = propsValue
      ? propsValue.contact_id
        ? propsValue.contact_id
        : ""
      : "";

    if (data) {
      formData.append("contact_id", data);
      dispatch(dispatchLoadingStatus(true));
      dispatch(contactEdit_API(formData));

    } else {
      dispatch(dispatchLoadingStatus(true));
      dispatch(contactAdd_API(formData));
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      email="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.contact_id
                                  ? "Location Edit"
                                  : "Location Add"
                                : "Location Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/LocationList">Location List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.contact_id
                                  ? "Location Edit"
                                  : "Location Add"
                                : "Location Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Title <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                maxLength={100}
                                className="form-control"
                                value={title}
                                onChange={(e) => handleTitle(e)}
                                placeholder="Enter Title"
                              />
                              <p className="errorr">{titleError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Email <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                placeholder="Enter Email"
                                maxLength={254}
                                className="form-control"
                                value={email}
                                onChange={(e) => handleEmail(e)}
                              />
                              <p className="errorr">{emailError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Phone <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                placeholder="Enter Phone"
                                className="form-control"
                                value={phone}
                                onChange={(e) => handlePhone(e)}
                                maxLength={16}
                              />
                              <p className="errorr">{phoneError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Zip Code <span className="text-danger">*</span></label>
                              <input value={pincode} className="form-control" maxLength={10} placeholder={"Enter Zip Code"} onChange={NumValid} />
                              <p className="errorr">{pincodeer}</p>
                            </div>
                            <div class="form-group col-sm-4">
                              <label htmlFor="title">Country <span className="text-danger">*</span></label>

                              <Select
                                options={CountryList && CountryList.length > 0 ? [{ label: "Country", value: '' }].concat(CountryList.map(e => ({ label: e.name, value: e.id }))) : [{ label: "Country", value: '' }]}
                                placeholder={"Country"}
                                value={SendCountry}
                                onChange={(e) => handleCountry(e)}

                              />
                              <p className="errorr">{SendCountryErr}</p>


                            </div>
                            <div class="form-group col-sm-4">
                              <label htmlFor="title">State <span className="text-danger">*</span></label>

                              <Select
                                options={StateList && StateList.length > 0 ? [{ label: "State", value: "" }].concat(StateList.map((e) => ({ label: e.name, value: e.id }))) : [{ label: "State", value: "" }]}
                                isClearable={false}
                                placeholder={"State"}
                                value={SendState}
                                onChange={(e) => handleState(e)}
                              />
                              <p className="errorr">{SendStateErr}</p>
                            </div>

                            <div class="form-group col-sm-4">
                              <label htmlFor="title">City <span className="text-danger">*</span></label>

                              <Select
                                options={CityList && CityList.length > 0 ? [{ label: "City", value: "" }].concat(CityList.map((e) => ({ label: e.name, value: e.id }))) : [{ label: "City", value: "" }]}
                                isClearable={false}
                                placeholder={"City"}
                                value={SendCity}
                                onChange={(e) => handleCity(e)}
                              />
                              <p className="errorr">{SendCityErr}</p>
                            </div>

                            <div class="form-group col-sm-12">
                              <label>Address <span className="text-danger">*</span></label>
                              <textarea
                                class="form-control"
                                placeholder="Enter Address"
                                rows="5"
                                maxLength={250}
                                value={address}
                                onChange={(e) => handleAdd(e)}
                                style={{ lineHeight: "22px" }}
                              ></textarea>
                              <p className="errorr">{addressError2}</p>
                            </div>
                            
                            <div class="form-group col-sm-12">
                              <label>Description <span className="text-danger">*</span></label>
                              <textarea
                                class="form-control"
                                placeholder="Enter Description"
                                rows="5"
                                maxLength={500}
                                value={description}
                                onChange={(e) => handleDes(e)}
                                style={{ lineHeight: "22px" }}
                              ></textarea>
                              <p className="errorr">{descriptionError2}</p>
                            </div>

                            <div class="form-group col-sm-6">

                            </div>
                          </div>


                          <button
                            className="btn iq-bg-danger mr-2"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>

                          <button
                            className="btn btn-primary "
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
