import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import CKEditor from 'react-ckeditor-component';
import { ReactComponent as CloseSvg } from '../../Assets/Images/icons8-no.svg';
import { dispatchLoadingStatus, pagesAdd_API, pagesEdit_API, pagesSequencelist_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import swal from 'sweetalert';

// Added by Parth Roka
function Add(props) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState("");
  const [nameErrorFlag, setNameErrorFlag] = useState(false);
  const [nameError, setNameError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("");
  const [contentErrorFlag, setContentErrorFlag] = useState(false);
  const [contentError, setContentError] = useState("");
  const [sequnceList, setSequnceList] = useState();
  const [propsValue, setPropsValue] = useState();
  const [sequnce, setSequnce] = useState("");
  const [sequnceError2, setSequnceError2] = useState("");
  const [sequnceErrorFlag, setSequnceErrorFlag] = useState("");

  const dispatch = useDispatch({});


  let pages_sequence_listReducer = useSelector(state => state.globalReducer.pages_sequence_listReducer);
  let token = localStorage.getItem("token");
  let phoneRegex = /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/;
  let nameRegex = /^[a-zA-Z\s-]*$/;

  useEffect(() => {
    if (!token) {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, [])

  // Pages Sequencelist API Call Added by Parth Roka
  useEffect(() => {
    if (title.length > 0 || title == "") {
      let formData = new FormData();
      formData.append('page_title', title);
      dispatch(pagesSequencelist_API(formData));
    }
  }, [title])

  useEffect(() => {
    if (pages_sequence_listReducer) {
      setSequnceList(pages_sequence_listReducer.data);
    }
    else {
      setSequnceList([]);
    }
  }, [pages_sequence_listReducer])



  function handleSequnce(e) {

    const re = /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/;
    let data = e.target.value.trim("") == "" ? "" : e.target.value;
    if (data) {
      if (!re.test(data)) {
        setSequnceErrorFlag(true);
      } else {
        setSequnce(e.target.value);
        setSequnceErrorFlag(false);
        setSequnceError2("");
      }
    } else {
      setSequnce("");
      setSequnceErrorFlag(true);
      setSequnceError2("Please enter sequence");
    }
  }

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          window.location.href = window.location.origin + "/PagesList";

        } else {
          console.log('Cancel press')
        }
      });

  }

  // Props value store Added by Parth Roka
  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setTitle(props.data.page_title);
      setSequnce(props.data.sequence_No);
      setSelectedFile(props.data.image);
      setContent(props.data.description);
    }
  }, [props])

  // Long Description onChnage Added by Parth Roka
  const handleChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
    if (newContent) {
      setContentErrorFlag(false);
      setContentError("");
    }
    else {
      setContentErrorFlag(true);
      setContentError("Please enter description");

    }
  };

  // Upload image onChange Added by Parth Roka
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")) {

        setSelectedFile(file);
        setSelectedFileError("");
      }
      else {
        setSelectedFileError("The image must be a file of type: jpg, png, jpeg");
      }
    }
    else {
      setSelectedFileError("");
    }


  };

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";
    setSelectedFile(null);
  };


  function handleTitle(e) {
    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z-]/g, '');
    setTitle(data.replace(/[^a-zA-Z-]/g, ''))
    if (data2) {
      if (data2.length >= 2) {
        setNameErrorFlag(false);
        if (!nameRegex.test(data)) {
          setNameErrorFlag(true);
          setNameError("Please enter a valid title");
        }
        else {
          setNameErrorFlag(false);
          setNameError("");
        }
      }
      else {
        setNameErrorFlag(true);
        setNameError("Title must be  2 to 100 characters long");
      }
    }
    else {
      setNameErrorFlag(true);
      setNameError("Please enter title");


    }

  }


  // Main function Added by Parth Roka
  function handleSubmit() {

    let data2 = title.trim() == "" ? "" : title.replace(/[^a-zA-Z-']/g, '');
    if (data2) {
      if (data2.length >= 2) {
        if (!nameRegex.test(title)) {
          setNameError("Please enter a valid title");
        }
        else {
          setNameError("");

        }
      }
      else {
        setNameError("Title must be  2 to 100 characters long");

      }
    }
    else {
      setNameError("Please enter title");


    }

    if (sequnce) {
      setSequnceErrorFlag(false);
      setSequnceError2("");
      if (!phoneRegex.test(sequnce)) {
        setSequnceErrorFlag(true);
        setSequnceError2("Please enter valid sequence ");
      }
      else {
        setSequnceErrorFlag(false);
        setSequnceError2("");
      }

    }
    else {
      setSequnceErrorFlag(true);
      setSequnceError2("Please enter sequence");

    }

    if (content) {
      setContentError("");
    }
    else {
      setContentError("Please enter description");
    }

    if (selectedFile) {
      setSelectedFileError("");
    }
    else {
      setSelectedFileError("Please select image");


    }

    if (nameErrorFlag == false && contentErrorFlag == false && sequnceErrorFlag == false && sequnce && title && content && selectedFile) {
      let formData = new FormData();
      formData.append('page_image', selectedFile ? selectedFile : "");
      formData.append('description', content);
      formData.append('page_title', title);
      formData.append('sequence_No', sequnce);
      let data = propsValue ? propsValue.pages_id ? propsValue.pages_id : "" : ""
      if (data) {
        dispatch(pagesEdit_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
      else {
        dispatch(pagesAdd_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />
        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title"> {propsValue ? propsValue.pages_id ? "Pages Edit" : "Pages Add" : "Pages Add"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/PagesList">Pages List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.pages_id ? "Pages Edit" : "Pages Add" : "Pages Add"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">

                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Title <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder='Enter Title' maxLength={100} value={title} onChange={(e) => handleTitle(e)} />
                              <p className='errorr'>{nameError}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">Upload Image <span className="text-danger">*</span></label>
                                <form >
                                  <div className="file">
                                    <div className="file__input" id="file__input">
                                      <input className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label className="file__input--label" accept='.png, .jpg .jpeg' htmlFor="customFile" data-text-btn="Choose Files">Add image:</label>
                                    </div>
                                  </div>

                                </form>
                                <p className='errorr'>{selectedFileError}</p>


                                {selectedFile && (
                                  <div className="choosed-files" style={{ position: 'relative' }}>
                                    <img
                                      src={typeof selectedFile === 'string' ? selectedFile : URL.createObjectURL(selectedFile)}
                                      alt="Selected Preview"
                                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />

                                    <CloseSvg className='point' width={18} height={18} onClick={() => handleRemoveFile()} style={{ zIndex: '99', position: 'absolute', right: '8px', top: '8px' }} />

                                  </div>
                                )}

                              </div>

                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Sequence <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" value={sequnce} placeholder='Enter Sequence' onChange={(e) => handleSequnce(e)} maxLength={2} />

                              <p className='errorr'>{sequnceError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label className="form-label">Used Sequence :</label>
                              <div className='d-flex Sequence-main-div'>
                                {
                                  sequnceList && sequnceList.length > 0 ? sequnceList.map((i) =>

                                    <p> {i.sequence_No}</p>
                                  ) : "No Sequence "
                                }
                              </div>
                            </div>
                            <div className="form-group col-sm-12">
                              <label>Long Description <span className="text-danger">*</span></label>
                              <CKEditor
                                activeclassName="p10"
                                content={content}
                                events={{
                                  change: handleChange,
                                }}
                                config={{ allowedContent: true }}
                              />
                              <p className='errorr'>{contentError}</p>

                            </div>
                          </div>
                          <button className="btn iq-bg-danger mr-2" onClick={() => handleMessage()}>Cancel</button>
                          <button className="btn btn-primary " onClick={() => handleSubmit()} >Submit</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Add