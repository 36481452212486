

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import {
  dispatchLoadingStatus,
  environmentalSectionedit_API,
} from "../../API/Action/globalAction";

// Added by Parth Roka
function Edit(props) {
  const { state } = useLocation();
  const dispatch = useDispatch({});
  const [fname, setFname] = useState("");
  const [content, setContent] = useState("");
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [roleError2, setRoleError2] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");
  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");
  let token = localStorage.getItem("token");
  let nameRe = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
  let Both = /^[a-zA-Z0-9\s]*$/;
  let nameRe2 = /^[a-zA-Z\s- ']*$/;

  useEffect(() => {
    if (token) {
      if (!window.location.href.includes("HomePageHighlightsEdit")) {
        window.location.href = window.location.origin + "/HomePageHighlightsEdit";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/HomePageHighlightsList";
      } else {
        console.log("Cancel press");
      }
    });
  }

  useEffect(() => {
    if (state) {
      setFname(state.Title);
      setContent(state.Description);
      setRole(state.Xnumber);
    }

  }, [state]);

  function handleFname(e) {
    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z- ']/g, '');
    setFname(data.replace(/[^a-zA-Z- ']/g, ''))
    if (data2) {

      if (data2.length >= 2) {
        setNameError(false);
        if (!nameRe2.test(data)) {
          setNameError(true);
          setNameError2("Please enter a valid title");


        }
        else {
          setNameError(false);
          setNameError2("");

        }
      }
      else {
        setNameError(true);
        setNameError2("Title must be  2 to 100 characters long");

      }
    }
    else {
      setNameError(true);
      setNameError2("Please enter title");
    }
  }

  function handleRole(e) {
    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z0-9-]/g, '');
    setRole(data.replace(/[^a-zA-Z0-9-]/g, ''))

    if (data2) {

      if (data2.length >= 2) {
        setRoleError(false);
        if (!Both.test(data)) {
          setRoleError(true);
          setRoleError2("Please enter a valid Xnumber");


        }
        else {
          setRoleError(false);
          setRoleError2("");

        }
      }
      else {
        setRoleError(true);
        setRoleError2("Xnumber must be  2 to 5 characters long");

      }
    }
    else {
      setRoleError(true);
      setRoleError2("Please enter Xnumber");


    }
  }

  const handleChange = (evt) => {
    let data2 = evt.target.value.trim() == "" ? "" : evt.target.value;
    setContent(data2);

    if (data2) {
      setContentError(false);
      setContentError2("");
    } else {
      setContentError(true);
      setContentError2("Please enter description");
    }
  };

  function handleSubmit() {
    setNameError2(!fname ? "Please enter title" : fname.length < 2 ? "Title must be 2 to 100 characters long" : !nameRe.test(fname) ? "Please enter valid title" : "");
    setContentError2(content ? "" : "Please enter description");
    let data2 = role.trim() == "" ? "" : role.replace(/[^a-zA-Z0-9-]/g, '');

    if (data2) {

      if (data2.length >= 2) {
        if (!Both.test(role)) {
          setRoleError2("Please enter a valid Xnumber");

        }
        else {
          setRoleError2("");

        }
      }
      else {
        setRoleError2("Xnumber must be  2 to 5 characters long");

      }
    }
    else {
      setRoleError2("Please enter Xnumber");


    }

    if (
      nameError == false &&
      contentError == false &&
      roleError == false &&
      fname &&
      content &&
      role
    ) {
      let formData = new FormData();

      formData.append("Description", content ? content : "");
      formData.append("Title", fname);
      formData.append("Xnumber", role);

      let data = state.id

      console.log("dataaaaaaaaaaa", data);

      formData.append("environmental_id", data);

      dispatch(environmentalSectionedit_API(formData));
      dispatch(dispatchLoadingStatus(true));

    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {state.id
                                ? state.id
                                  ? "Home Page Highlights Edit"
                                  : "Home Page Highlights Edit"
                                : "Home Page Highlights Edit"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/HomePageHighlightsList">
                                Home Page Highlights List
                              </a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {state.id
                                ? state.id
                                  ? "Home Page Highlights Edit"
                                  : "Home Page Highlights Edit"
                                : "Home Page Highlights Edit"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Title <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                value={fname}
                                placeholder="Enter Title"
                                maxLength={100}
                                onChange={(e) => handleFname(e)}
                              />
                              <p className="errorr">{nameError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Xnumber <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                value={role}
                                maxLength={5}
                                placeholder="Enter Xnumber"
                                onChange={(e) => handleRole(e)}
                              />
                              <p className="errorr">{roleError2}</p>
                            </div>

                            <div class="form-group col-sm-12">
                              <label>Description <span className="text-danger">*</span> </label>
                              <textarea
                                class="form-control"
                                rows="5"
                                placeholder="Enter Description"
                                value={content}
                                maxLength={100}
                                onChange={(e) => handleChange(e)}
                                style={{ lineHeight: "22px" }}
                              ></textarea>
                              <p className="errorr">{contentError2}</p>
                            </div>
                          </div>

                          <button
                            className="btn iq-bg-danger mr-2"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary "
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
