import axios from "axios";
import {
  baseURL, pagesAdd_URL, pagesAddReducer, IS_LOADING, Login_URL, LoginReducer, pagesList_URL, pagesListReducer, pagesStatus_URL, pagesStatusReducer, pagesEdit_URL, pagesEditReducer, productListReducer, productList_URL, getAllCategoriesTitleReducer, getAllCategoriesTitle_URL, getfabricCategory_URL, getfabricCategoryReducer, getPriceRange_URL, getPriceRangeReducer, getDeals_URL, getDealsReducer, productAddReducer, productAdd_URL, productEdit_URL, productEditReducer, productStatus_URL, productStatusReducer, ourStoryTeamListReducer, ourStoryTeamList_URL, ourTeamStatusReducer, ourTeamStatus_URL, ourTeamAdd_URL, ourTeamAddReducer, ourTeamEdit_URL, ourTeamEditReducer, dealsCategoryAdd_URL, dealsCategoryAddReducer, dealsCategoryEdit_URL, dealsCategoryEditReducer, dealsStatusReducer, dealsStatus_URL, febricCategoryAdd_URL, febricCategoryAddReducer, getfebricCategoryList_URL, getfebricCategoryListReducer, typeOfFabricsStatus_URL, typeOfFabricsStatusReducer, fabricCategoryEdit_URL, fabricCategoryEditReducer, contactList_URL, contactReducer, contactAdd_URL, contactAddReducer, contactEdit_URL, contactEditReducer, userInquiryGet_URL, userInquiryGetReducer, contactStatus_URL, contactStatusReducer, priceRangeAdd_URL, priceRangeAddReducer, getpriceRangeList_URL, getpriceRangeListReducer, pricestatusReducer, priceRangeEdit_URL, priceRangeEditReducer, homepagesAdd_URL, homepagesAddReducer, homepagesEditReducer, getAllHomePages_URL, getAllHomePagesReducer, pricestatus_URL, homepagesEdit_URL, InqueryList_URL, InqueryListReducer, homePagesStatus_URL, homePagesStatusReducer

  , addTestimonials_URL,
  addTestimonialsReducer,
  Testimonialslist_URL,
  TestimonialslistReducer,
  editTestimonialReducer,
  editTestimonial_URL,
  Testimonialsstatus_URL,
  TestimonialsstatusReducer,
  userRegister_URL,
  userRegisterReducer,
  allAdminlist_URL,
  allAdminlistReducer,
  ourTeamSequenceURL,
  ourTeamSequenceReducer,
  userEditReducer,
  userEdit_URL,
  adminstatusReducer,
  adminstatus_URL,
  pages_sequence_list_API,
  pages_sequence_list_URL,
  pages_sequence_listReducer,
environmentalSectionlist_URL,
environmentalSectionReducer,
environmentalSectionedit_URL,
environmentalSectioneditReducer,
forgotPasswordOtpSendInEmail_URL,
forgotPasswordOtpSendInEmailReducer,
OtpMatch_URL,
OtpMatchReducer,
passwordChangeReducer,
passwordChange_URL,
productdetails_URL,
productdetailsReducer,
product_image_delete_URL,
product_image_deleteReducer,
product_size_add_URL,
product_size_addReducer,
product_size_edit_URL,
product_size_editReducer,
accessDatadetails_URL,
accessDatadetailsReducer,
downloadInquiries_URL,
downloadInquiriesReducer,
getstatecityReg,
getstate_URL,
countrylist_URL,
countrylistReg,
product_addtestReg,
product_addtest_URL,
citieslist_URL,
citieslistReg,
} from "../../OtherFiles/Constant";
import swal from "sweetalert"; import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// toast.configure();



function getAsios() {
  let token = localStorage.getItem("token");

  if (token) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Token: ` ${token}`,
      },
    });
  } else {
    return axios.create({
      baseURL: baseURL,
      headers: {
        "Access-Control-Allow-Origin": "*",

      },
    });
  }
}

export function dispatchLoadingStatus(loading, value) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}

export function pagesAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(pagesAdd_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: pagesAddReducer, payload: response.data });

          swal("", "Page added successfully", "success").then(function () {
            window.location.href = window.location.origin + "/PagesList";
          });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function pagesEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(pagesEdit_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: pagesEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/PagesList";
          });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function Login_API(data, email) {
  return function (dispatch) {
    return getAsios()
      .post(Login_URL, data)
      .then((response) => {
        debugger
        dispatch(dispatchLoadingStatus(false));
        console.log("response", response);

        if (response.data.errorcode == 0) {
          dispatch({ type: LoginReducer, payload: response.data });
          toast.success("Login Successfully");
          localStorage.setItem("valid", response.data.data.accessData);
          localStorage.setItem("user_idd", response.data.data.user_id);

          localStorage.setItem("emailAdmin", email);
          localStorage.setItem("nameAdmin", response.data.data.name);

          localStorage.setItem(Login_URL, true);
          localStorage.setItem("token", response.data.token);
        } else {
          dispatch({ type: LoginReducer, payload: "" });

          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}



export function pagesList_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(pagesList_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: pagesListReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function pagesStatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(pagesStatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your page is inactive!", { icon: "success" });
          } else {
            swal("Your page is active!", { icon: "success" });
          }
          dispatch({ type: pagesStatusReducer, payload: response.data });

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function homePagesStatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(homePagesStatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your page is inactive!", { icon: "success" });
          } else {
            swal("Your page is active!", { icon: "success" });
          }
          dispatch({ type: homePagesStatusReducer, payload: response.data });

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function ProductList_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(productList_URL, data)
      .then((response) => {

        console.log("productList_URL" , response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.success == true) {
          dispatch({ type: productListReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function getAllCategoriesTitle_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(getAllCategoriesTitle_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.success == true) {
          dispatch({ type: getAllCategoriesTitleReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function getfabricCategory_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(getfabricCategory_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getfabricCategoryReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function getPriceRange_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(getPriceRange_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getPriceRangeReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function productAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(productAdd_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: productAddReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/ProductList";
          });
        }
        else if(response.data.errorcode == 2) {
          dispatch({ type: productAddReducer, payload: response.data });


        }
        else{
          swal("", response.data.message, "error");

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function productEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(productEdit_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: productEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/ProductList";
          });
        }
        else if(response.data.errorcode == 2) {
          dispatch({ type: productEditReducer, payload: response.data });


        }
        else{
          swal("", response.data.message, "error");

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function productStatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(productStatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your product is inactive!", { icon: "success" });
          } else {
            swal("Your product is active!", { icon: "success" });
          }
          dispatch({ type: productStatusReducer, payload: response.data });

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}




export function ourStoryTeamList_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(ourStoryTeamList_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        console.log("ourStoryTeamListReducer", response);

        if (response.data.errorcode == 0) {
          dispatch({ type: ourStoryTeamListReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function ourTeamStatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(ourTeamStatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your team member is inactive!", { icon: "success" });
          } else {
            swal("Your team member is active!", { icon: "success" });
          }
          dispatch({ type: ourTeamStatusReducer, payload: response.data });

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function ourTeamAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(ourTeamAdd_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: ourTeamAddReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/OurTeamList";
          });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function ourTeamEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(ourTeamEdit_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: ourTeamEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/OurTeamList";
          });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function getDeals_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(getDeals_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getDealsReducer, payload: response.data });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function dealsCategoryAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(dealsCategoryAdd_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: dealsCategoryAddReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/DealsList";
          });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function dealsCategoryEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(dealsCategoryEdit_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: dealsCategoryEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/DealsList";
          });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function dealsStatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(dealsStatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your deal is inactive!", { icon: "success" });
          } else {
            swal("Your deal is active!", { icon: "success" });
          }
          dispatch({ type: dealsStatusReducer, payload: response.data });

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


//added by mansi vaghela
export function febricCategoryAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(febricCategoryAdd_URL, data)
      .then((response) => {
        console.log("resopnse", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: febricCategoryAddReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/FabricsCategoryList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by mansi vaghela
export function getfebricCategoryList_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(getfebricCategoryList_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        console.log("ourStoryTeamListReducer", response);

        if (response.data.errorcode == 0) {
          dispatch({
            type: getfebricCategoryListReducer,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by mansi vaghela
export function typeOfFabricsStatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(typeOfFabricsStatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your fabrics category is inactive!", { icon: "success" });
          } else {
            swal("Your fabrics category is active!", { icon: "success" });
          }
          dispatch({
            type: typeOfFabricsStatusReducer,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by mansi vaghela
export function fabricCategoryEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(fabricCategoryEdit_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: fabricCategoryEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/FabricsCategoryList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function contactList_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(contactList_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: contactReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}
export function contactAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(contactAdd_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {

          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/LocationList";
          });
          dispatch({ type: contactAddReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function contactEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(contactEdit_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/LocationList";
          });
          dispatch({ type: contactEditReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function userInquiryGet_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(userInquiryGet_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: userInquiryGetReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}



export function contactStatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(contactStatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your location is inactive!", { icon: "success" });
          } else {
            swal("Your location is active!", { icon: "success" });
          }
          dispatch({ type: contactStatusReducer, payload: response.data });

        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


//added by mansi vaghela
export function priceRangeAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(priceRangeAdd_URL, data)
      .then((response) => {
        console.log("resopnse", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: priceRangeAddReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/PriceRangeList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


//added by mansi vaghela
export function getpriceRangeList_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(getpriceRangeList_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        console.log("getpriceRangeListReducer", response);

        if (response.data.errorcode == 0) {
          dispatch({
            type: getpriceRangeListReducer,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}




//added by mansi vaghela
export function pricestatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(pricestatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your price range is inactive!", { icon: "success" });
          } else {
            swal("Your price range is active!", { icon: "success" });
          }
          dispatch({
            type: pricestatusReducer,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}



//added by mansi vaghela
export function priceRangeEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(priceRangeEdit_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: priceRangeEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/PriceRangeList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by Mansi Vaghela
export function homepagesAdd_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(homepagesAdd_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: homepagesAddReducer, payload: response.data });

          swal("", "Page added successfully", "success").then(function () {
            window.location.href = window.location.origin + "/HomeList";
          });

        } else {
          swal("", response.data.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by mansi vaghela
export function homepagesEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(homepagesEdit_URL, data)
      .then((response) => {
        console.log("myresssss", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: homepagesEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/HomeList";
          });
        }
        else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function getAllHomePages_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(getAllHomePages_URL, data)
      .then((response) => {
        console.log("myres", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.success == true) {
          dispatch({ type: getAllHomePagesReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

export function InqueryList_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(InqueryList_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.success == true) {
          dispatch({ type: InqueryListReducer, payload: response.data });
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}



//added by mansi vaghela  3/1/24
export function addTestimonials_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(addTestimonials_URL, data)
      .then((response) => {
        console.log("resopnse", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: addTestimonialsReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/TestimonialList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by mansi vaghela 3/1/23
export function Testimonialslist_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(Testimonialslist_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        console.log("TestimonialslistReducer", response);

        if (response.data.errorcode == 0) {
          dispatch({
            type: TestimonialslistReducer,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


//added by mansi vaghela 3/1/23
export function editTestimonial_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(editTestimonial_URL, data)
      .then((response) => {
        console.log("myresssss", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: editTestimonialReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/TestimonialList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}



//added by mansi vaghela 3/1/24
export function Testimonialsstatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(Testimonialsstatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your testimonial is inactive!", { icon: "success" });
          } else {
            swal("Your testimonial is active!", { icon: "success" });
          }
          dispatch({ type: TestimonialsstatusReducer, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}




//added by mansi vaghela 4/1/24

export function userRegister_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(userRegister_URL, data)
      .then((response) => {
        console.log("resopnseE", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: userRegisterReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/AdminUserManagementList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}



//added by mansi vaghela 4/1/24
export function allAdminlist_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(allAdminlist_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        console.log("allAdminlistReducer", response);

        if (response.data.errorcode == 0) {
          dispatch({
            type: allAdminlistReducer,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function userEdit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(userEdit_URL, data)
      .then((response) => {
        console.log("resopnseE", response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: userEditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/AdminUserManagementList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function ourTeamSequence_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(ourTeamSequenceURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode === 1) {


          dispatch({
            type: ourTeamSequenceReducer,
            payload: response.data,
          });
        }
        else {
          dispatch({
            type: ourTeamSequenceReducer,
            payload: [],
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


//added by mansi vaghela 3/1/24
export function adminstatus_API(data, is_active) {
  return function (dispatch) {
    return getAsios()
      .post(adminstatus_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          if (is_active == 1) {
            swal("Your Admin is inactive!", { icon: "success" });
          } else {
            swal("Your Admin is active!", { icon: "success" });
          }
          dispatch({ type: adminstatusReducer, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


export function pagesSequencelist_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(pages_sequence_list_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        console.log("allAdminlistReducer", response);

        if (response.data.errorcode == 0) {
          dispatch({
            type: pages_sequence_listReducer,
            payload: response.data,
          });
        }
        else{
          dispatch({
            type: pages_sequence_listReducer,
            payload: "",
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}


//added by mansi vaghela 4/1/24
export function environmentalSectionlist_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(environmentalSectionlist_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        console.log("environmentalSectionReducer", response);

        if (response.data.errorcode == 0) {
          dispatch({
            type: environmentalSectionReducer,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by mansi vaghela 3/1/23
export function environmentalSectionedit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(environmentalSectionedit_URL, data)
      .then((response) => {
        console.log("myresssss", response);
        dispatch(dispatchLoadingStatus(false));
      
        if (response.data.errorcode == 0) {
          dispatch({ type: environmentalSectioneditReducer, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href = window.location.origin + "/HomePageHighlightsList";
          });
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}

//added by mansi vaghela  3/1/24
export function forgotPasswordOtpSendInEmail_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(forgotPasswordOtpSendInEmail_URL, data)
      .then((response) => {
        console.log("resopnse", response.data.errorcode);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          localStorage.setItem("user_id",response.data.user_id)
          dispatch({ type: forgotPasswordOtpSendInEmailReducer, payload: response.data });

          // swal("", response.data.message, "success").then(function () {
          //   window.location.href = window.location.origin + "/Otp";
          // });
        } else {
          
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
}



//added by mansi vaghela 4/1/24
export function OtpMatch_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(OtpMatch_URL, data)
      .then((response) => {
        console.log("www",response);
        dispatch(dispatchLoadingStatus(false));
        console.log("OtpMatchReducer", response);
        console.log("resotp",response);
                
        if (response.data.errorcode == 0) {
          dispatch({type: OtpMatchReducer, payload: response.data});
          localStorage.removeItem("noPage")
          swal("",response.data.message,"success")
        }else{
          swal("", response?.data?.message, "error");
        }

      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", err?.response?.data?.message, "error");
      });
  };
}

export function passwordChange_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(passwordChange_URL, data)
      .then((response) => {
        console.log("password",response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: passwordChangeReducer, payload: response.data });
          swal("",response.data.message,"success")
          toast.success(response.data.message);
        } else {
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function productdetails_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(productdetails_URL, data)
      .then((response) => {
        console.log("password",response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: productdetailsReducer, payload: response.data });
      
        } 
        else{
          dispatch({ type: productdetailsReducer, payload: "" });

        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}


export function product_image_deleteReducer_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(product_image_delete_URL, data)
      .then((response) => {
        console.log("password",response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: product_image_deleteReducer, payload: response.data });
      
        } 
        else{
          dispatch({ type: product_image_deleteReducer, payload: "" });

        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function product_size_add_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(product_size_add_URL, data)
      .then((response) => {
        console.log("password",response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: product_size_addReducer, payload: response.data });
      
        } 
        else{
          dispatch({ type: product_size_addReducer, payload: "" });

        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}


export function product_size_edit_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(product_size_edit_URL, data)
      .then((response) => {
        console.log("password",response);
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: product_size_editReducer, payload: response.data });
      
        } 
        else{
          dispatch({ type: product_size_editReducer, payload: "" });

        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}



//added by mansi vaghela 3/1/23

export const displaySwal = () => {
  swal("","You are unable to access this feature. Please contact your administrator to enable it.","warning")
};

//added by mansi vaghela 3/1/23
export function accessDatadetails_API(data) {
return function (dispatch) {
  return getAsios()
    .post(accessDatadetails_URL, data)
    .then((response) => {
      console.log("myresssss", response);
      dispatch(dispatchLoadingStatus(false));
      
      if (response.data.errorcode == 0) {
        
        dispatch({ type: accessDatadetailsReducer, payload: response.data });
     
        // swal("", response.data.message, "success").then(function () {
        //   window.location.href = window.location.origin + "/EnvironmentList";
        // });
      } else {
        swal("", response.data.message, "error");
      }
    })
    .catch((err) => {
      dispatch(dispatchLoadingStatus(false));
      // swal("", err?.response?.data?.message, "error");
    });
};
}


export function downloadInquiries_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(downloadInquiries_URL, data)
      .then((response) => {
        console.log("myresssss", response);

        dispatch(dispatchLoadingStatus(false));
        
        if (response.data.errorcode == 0) {
          
          window.open(response.data.path)
          dispatch({ type: downloadInquiriesReducer, payload: response.data });
       
        
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        // swal("", err?.response?.data?.message, "error");
      });
  };
  }


  export function countrylist_API(data) {
    return function (dispatch) {
      return getAsios()
        .post(countrylist_URL, data)
        .then((response) => {
          console.log("responseStateCity",response);
          dispatch(dispatchLoadingStatus(false));
          if (response.data.errorcode == 0) {
            dispatch({ type: countrylistReg, payload: response.data });
          }
          else{
            dispatch({ type: countrylistReg, payload: "" });

          }
        })
        .catch((err) => {
          dispatch(dispatchLoadingStatus(false));
          swal("", "Something went wrong, please try again", "error");
        });
    };
  }
  

  export function getstatecityAPI(data) {
    return function (dispatch) {
      return getAsios()
        .post(getstate_URL, data)
        .then((response) => {
          console.log("responseStateCity",response);
          dispatch(dispatchLoadingStatus(false));
          if (response.data.errorcode == 0) {
            dispatch({ type: getstatecityReg, payload: response.data });
          }
          else{
            dispatch({ type: getstatecityReg, payload: "" });

          }
        })
        .catch((err) => {
          dispatch(dispatchLoadingStatus(false));
          swal("", "Something went wrong, please try again", "error");
        });
    };
  }
  
  export function citieslist_API(data) {
    return function (dispatch) {
      return getAsios()
        .post(citieslist_URL, data)
        .then((response) => {
          console.log("responseCity",response);
          dispatch(dispatchLoadingStatus(false));
          if (response.data.errorcode == 0) {
            dispatch({ type: citieslistReg, payload: response.data });
          }
          else{
            dispatch({ type: citieslistReg, payload: "" });

          }
        })
        .catch((err) => {
          dispatch(dispatchLoadingStatus(false));
          swal("", "Something went wrong, please try again", "error");
        });
    };
  }
  export function product_addtest_API(data) {
    return function (dispatch) {
      return getAsios()
        .post(product_addtest_URL, data)
        .then((response) => {
          dispatch(dispatchLoadingStatus(false));
          if (response.data.errorcode == 0) {
            dispatch({ type: product_addtestReg, payload: response.data });
  
            swal("", response.data.message, "success").then(function () {
              window.location.href = window.location.origin + "/ProductList";
            });
          }
          else if(response.data.errorcode == 2) {
            dispatch({ type: product_addtestReg, payload: response.data });

  
          }
          else{
            swal("", response.data.message, "error");

          }
  
        })
        .catch((err) => {
          dispatch(dispatchLoadingStatus(false));
          // swal("", err?.response?.data?.message, "error");
        });
    };
  }
  