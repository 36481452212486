import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { InqueryList_API, dispatchLoadingStatus, downloadInquiries_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import moment from "moment";
import Select from 'react-select';

// Added by Parth Roka
function List() {
  let InqueryListReducer = useSelector(state => state.globalReducer.InqueryListReducer);
  let range = [{ label: "5", value: "5" }, { label: "10", value: "10" }, { label: "25", value: "25" }, { label: "50", value: "50" }, { label: "100", value: "100" },];
  const [sendRange, setSendRange] = useState({ label: "5", value: "5" });
  const [startValue, setStartValue] = useState(0);
  let token = localStorage.getItem("token");
  const [page, setpage] = useState(1);
  const totalCount = InqueryListReducer ? InqueryListReducer.count : "";
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch({});
  const [order, setOrder] = useState("ASC");
  const [datasrc, setdatasrc] = useState('');

  useEffect(() => {
    if (token) {
      if (!window.location.href.includes("Inquiry")) {
        window.location.href = window.location.origin + "/Inquiry";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }

    dispatch(dispatchLoadingStatus(true))
    let formData = new FormData();
    formData.append("start", 0);
    setStartValue(0);
    setpage(1);
    formData.append("limit", sendRange && sendRange.value);
    dispatch(InqueryList_API(formData))


  }, [])

  function handleRange(e) {
    let data = e.value;
    setSendRange(e);
    if (data) {
      Apicall(0, data);
      setItemsPerPage(data)
      setCurrentPage(1);
    }
  }

  const handlePageChange = (page, count) => {
    setCurrentPage(page);
    if (InqueryListReducer && InqueryListReducer.count > itemsPerPage) {
      Apicall((page - 1) * itemsPerPage, sendRange && sendRange.value)
    }
  };

  const renderPagination = () => {
    const pagesToShow = 3;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    const paginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link" style={{ cursor: InqueryListReducer && InqueryListReducer.count > itemsPerPage ? 'pointer' : '' }} onClick={() => { handlePageChange(i); setpage(i) }}>
            {i}
          </a>
        </li>
      );
    }

    return paginationItems.length > 0 ? paginationItems : <li className="page-item disabled"><span className="page-link">No pages</span></li>;
  };

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1));
      console.log("datasrc", datasrc);
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => ((a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };

  const Sortt22 = (col) => {
    if (order === "ASC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...datasrc].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setdatasrc(sorted);
      setOrder("ASC");
    }
  };


  useEffect(() => {
    if (InqueryListReducer) {
      if (InqueryListReducer.errorcode == 1) {
        setdatasrc([]);
      } else {
        setdatasrc(InqueryListReducer ? (InqueryListReducer.data ? InqueryListReducer.data : []) : []);
      }
    }
  }, [InqueryListReducer]);

  function Apicall(start, data) {
    let formData = new FormData();
    formData.append("start", start);
    formData.append("limit", data ? data : "5");
    setStartValue(start);
    setpage(1);
    dispatch(dispatchLoadingStatus(true))
    dispatch(InqueryList_API(formData));
  }

  function handleDownload() {
    dispatch(dispatchLoadingStatus(true))
    dispatch(downloadInquiries_API());
  }

  return (
    <>
      <div className="wrapper">

        <Sidebar />
        <Loader />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Inquiry List</h4>
                    </div>

                  </div>
                  <div className="iq-card-body">
                    <div className="table-responsive">
                      <div className="row justify-content-between">
                        <div className="col-sm-12">
                          <div className="user-list-files d-flex float-right">
                            <span className="table-add float-right mb-3 mr-2">
                              <button className="btn w-100 btn-primary" onClick={() => handleDownload()}>

                                Download File
                              </button>

                            </span>
                          </div>
                        </div>

                      </div>

                      <div className='col-md-4'>
                        <div className="form-group mb-0">
                          <label htmlFor="fname">Showing Inquiries </label>
                          <Select className='w-100'
                            options={range ? range.map((dataItem) => ({
                              value: dataItem.value,
                              label: dataItem.label,
                            })) : [{ label: "Select", value: "" }]}
                            placeholder="Select"
                            isClearable={false}
                            value={sendRange}
                            onChange={(e) => handleRange(e)}
                          />
                        </div>
                      </div>
                      <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Name
                              <i onClick={() => Sortt("full_name")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>
                            <th>Email
                              <i onClick={() => Sortt("email")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}

                            </th>
                            <th>Message
                              <i onClick={() => Sortt("message")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}
                            </th>

                            <th>Date
                              <i onClick={() => Sortt22("created_at")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>{" "}

                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {datasrc && datasrc.length > 0 ? datasrc.map((i, index) =>
                            <tr>
                              <td> {((page * parseInt(sendRange.value)) + index + 1) - parseInt(sendRange.value)}.</td>
                              <td>{i.full_name}</td>
                              <td>{i.email}</td>
                              <td>{i.message}</td>
                              <td>
                                {i.created_at
                                  ? moment(i.created_at).format("MM/DD/YYYY")
                                  : "-"}
                              </td>
                            </tr>
                          ) : <tr className='bg-white border-0'> <td colSpan={4} className='border-0'> <div className='col-md-12 w-100 mt-4'><p className='text-center'>No Data Found</p> </div></td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <div className="row justify-content-between mt-3">
                      <div id="user-list-page-info" className="col-md-6">
                        {
                          InqueryListReducer && InqueryListReducer.count ?
                            <span>
                              Showing {startValue + 1} to {InqueryListReducer.count > startValue + parseInt(sendRange.value) ? startValue + parseInt(sendRange.value) : InqueryListReducer.count} of {InqueryListReducer.count} entries
                            </span>
                            : ""}
                      </div>
                      <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end mb-0">
                            <li className={`page-item  ${currentPage === 1 ? 'disabled' : 'page-item point'}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage - 1, "less")} tabIndex="-1" aria-disabled="true">
                                Previous
                              </a>
                            </li>
                            {renderPagination()}
                            <li className={`page-item  ${currentPage === totalPages ? 'disabled' : 'page-item point'}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default List