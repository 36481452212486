import React, { useEffect, useState } from "react";
import { ReactComponent as CloseSvg } from "../../../Assets/Images/icons8-no.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Loader/Loader";
import Sidebar from "../../Sidebar/Sidebar";
import {
  dispatchLoadingStatus,
  fabricCategoryEdit_API,
  febricCategoryAdd_API,
  getfabricCategory_API,
} from "../../../API/Action/globalAction";

// Added by Mansi Vaghela
function FebricsCategoryAdd(props) {
  const [propsValue, setPropsValue] = useState();
  const [ImageName, setImageName] = useState("");
  const dispatch = useDispatch({});
  const [productName, setProductName] = useState();
  const [sendDes, setSendDes] = useState();
  const [profuctError, setProductError] = useState(false);
  const [productError2, setProductError2] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError2, setSelectedFileError2] = useState("");
  let token = localStorage.getItem("token");
  let nameRe = /^[a-zA-Z\s]*$/;



  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/FabricsCategoryList";
      }
    });
  }


  useEffect(() => {
    if (!token) {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
    dispatch(getfabricCategory_API());
  }, []);

  useEffect(() => {
    if (props.data) {
      console.log("hii", props.data);
      setPropsValue(props.data);
      setProductName(props.data.type_of_category);
      setSelectedFile(props.data.image);
      setSendDes(props.data.description);
    }
  }, [props]);

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";
    setImageName("");
    setSelectedFile(null);
  };


  function handleProductName(e) {
    let data = e.target.value.trim() == "" ? "" : e.target.value;

    if (nameRe.test(data)) {
      setProductName(data);
    }
    if (data) {
      if (nameRe.test(data)) {
        setProductError(false);
        setProductError2('')
        if (data.length >= 2) {
          setProductError(false);
          setProductError2('')
        }
        else {
          setProductError(true);
          setProductError2("Title must be  2 to 100 characters long")
        }
      }
      else {
        setProductError(true);
        setProductError2('Please enter a valid title')

      }
    }
    else {
      setProductError(true);
      setProductError2('Please enter title')

    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")
      ) {
        setImageName(event.target.files[0].name)

        setSelectedFile(file);
        setSelectedFileError2("");
      } else {
        setSelectedFileError2(
          "The image must be a file of type: jpg, png, jpeg"
        );
      }
    } else {
      setSelectedFileError2("");
    }
  };

  function handleDes(e) {
    setSendDes(e.target.value.trim() == "" ? "" : e.target.value);
  }

  function handleSubmit() {
    if (productName) {
      if (productName.length >= 2) {
        setProductError2("");
      } else {
        setProductError2("Title must be  2 to 100 characters long");
      }
    } else {
      setProductError2("Please enter title");
    }

    if (!profuctError && productName) {
      let formData = new FormData();

      formData.append("type_of_category", productName);
      formData.append("category_image", selectedFile ? selectedFile : "");
      formData.append("description", sendDes ? sendDes : "");

      if (propsValue) {
        if (propsValue.fabricCategory_id) {
          let formData2 = new FormData();

          formData2.append("type_of_category", productName);
          formData2.append("category_image", selectedFile ? selectedFile : "");

          formData2.append(
            "fabricCategory_id",
            props ? props.data.fabricCategory_id : ""
          );
          formData2.append("description", sendDes ? sendDes : "");
          dispatch(fabricCategoryEdit_API(formData2));
          dispatch(dispatchLoadingStatus(true));
        } else {
          dispatch(febricCategoryAdd_API(formData));
          dispatch(dispatchLoadingStatus(true));
        }
      } else {
        dispatch(febricCategoryAdd_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.fabricCategory_id
                                  ? "Fabrics Category Edit"
                                  : "Fabrics Category Add"
                                : "Fabrics Category Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/FabricsCategoryList">Fabrics Category List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.fabricCategory_id
                                  ? "Fabrics Category Edit"
                                  : "Fabrics Category Add"
                                : "Fabrics Category Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className="row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Title <span className="text-danger">*</span></label>
                              <input
                                className="form-control"
                                value={productName}
                                maxLength={100}
                                placeholder="Enter Title"
                                onChange={(e) => handleProductName(e)}
                              />
                              <p className="errorr">{productError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">
                                  Upload Image
                                </label>
                                <form>
                                  <div className="file">
                                    <div
                                      className="file__input"
                                      id="file__input"
                                    >
                                      <input
                                        className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label className="file__input--label" accept='.png, .jpg .jpeg' htmlFor="customFile" data-text-btn="Choose Files">{ImageName ? ImageName : "Add image:"}</label>
                                    </div>
                                  </div>
                                </form>
                                <p className="errorr">{selectedFileError2}</p>

                                {selectedFile && (
                                  <div
                                    className="choosed-files"
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      src={
                                        typeof selectedFile === "string"
                                          ? selectedFile
                                          : URL.createObjectURL(selectedFile)
                                      }
                                      alt="Selected Preview"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "200px",
                                      }}
                                    />

                                    <CloseSvg
                                      className="point"
                                      width={18}
                                      height={18}
                                      onClick={() => handleRemoveFile()}
                                      style={{
                                        zIndex: "99",
                                        position: "absolute",
                                        right: "8px",
                                        top: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group col-sm-12">
                              <label>Description </label>

                              <textarea
                                className="form-control"
                                name="address"
                                rows="5"
                                maxLength={250}
                                value={sendDes}
                                placeholder="Enter Description"
                                onChange={(e) => handleDes(e)}
                                style={{ lineHeight: "22px" }}
                              />
                            </div>
                          </div>

                          <button
                            className="btn iq-bg-danger mr-2"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>

                          <button
                            className="btn btn-primary "
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FebricsCategoryAdd;
