import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { ReactComponent as CloseSvg } from '../../Assets/Images/icons8-no.svg';
import { dispatchLoadingStatus, ourTeamAdd_API, ourTeamEdit_API, ourTeamSequence_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom"
import Loader from '../Loader/Loader';
import swal from 'sweetalert';
import Select from 'react-select';

// Added by Parth Roka
function Add(props) {
  const { state } = useLocation();

  const [propsValue, setPropsValue] = useState();
  const [ImageName, setImageName] = useState("");
  const [fname, setFname] = useState('');
  const [department, setDepartment] = useState('');
  const [role, setRole] = useState('');
  const [sendDepartment, setSendDepartment] = useState([{ label: "Select", value: "" }]);
  const [nameErrorFlag, setNameErrorFlag] = useState(false);
  const [nameError, setNameError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("");
  const [roleErrorFlag, setRoleErrorFlag] = useState(false);
  const [roleError, setRoleError] = useState("");
  const [departmentErrorFlag, setDepartmentErrorFlag] = useState(false);
  const [departmentError, setDepartmentError] = useState("");
  const [sequnceList, setSequnceList] = useState([]);
  const [sequnce, setSequnce] = useState("");
  const [sequnceError, setSequnceError] = useState("");
  const [sequnceErrorFlag, setSequnceErrorFlag] = useState("");
  const dispatch = useDispatch({});
  let token = localStorage.getItem("token");
  let nameRe = /^[a-zA-Z\s-]*$/;
  let nameRe2 = /^[a-zA-Z\s-']*$/;
  let phoneRe = /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/;
  let ourTeamSequenceReducer = useSelector(state => state.globalReducer.ourTeamSequenceReducer);

  let dataList = [{ value: "Leadership", label: "Leadership" }, { value: "Development", label: "Development" }, { value: "Design", label: "Design" }
    , { value: "Marketing", label: "Marketing" }, { value: "Front End", label: "Front End" }, { value: "Back End", label: "Back End" }
    , { value: "Quality Assurance (QA)", label: "Quality Assurance (QA)" }, { value: "Others", label: "Others" }]

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          window.location.href = window.location.origin + "/OurTeamList";

        } else {
          console.log('Cancel press')


        }
      });

  }

  useEffect(() => {
    if (state == 'add') {
      debugger
      setSequnceList([])
    }
  }, [state])

  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setFname(props.data.name);
      setSelectedFile(props.data.image);
      let data2 = dataList && dataList.filter((i) => i.value == props.data.department);
      console.log("fff", data2);
      setSendDepartment(data2 && data2.length > 0 ? data2[0] : [{ value: "Others", label: "Others" }]);
      setDepartment(data2 && data2.length > 0 ? "" : props.data.department)
      setRole(props.data.role);
      setSequnce(props.data.sequence_No);
    }
  }, [props])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")) {

        setSelectedFile(file);
        setImageName(event.target.files[0].name)
        setSelectedFileError("");
      }
      else {
        setSelectedFileError("The image must be a file of type: jpg, png, jpeg");

      }
    }
    else {
      setSelectedFileError("");
    }


  };

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";
    setImageName("");
    setSelectedFile(null);
  };

  function handleSequnce(e) {

    const re = /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/;
    let data = e.target.value.trim("") == "" ? "" : e.target.value;
    if (data) {
      if (!re.test(data)) {
        setSequnceErrorFlag(true);
      } else {
        setSequnce(e.target.value);
        setSequnceErrorFlag(false);
        setSequnceError("");


      }
    } else {
      setSequnce("");
      setSequnceErrorFlag(true);
      setSequnceError("Please enter sequence");
    }

  }

  function handleRole(e) {

    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z-]/g, '');


    setRole(data.replace(/[^a-zA-Z-]/g, ''))

    if (data2) {

      if (data2.length >= 2) {
        setRoleErrorFlag(false);
        if (!nameRe.test(data)) {
          setRoleErrorFlag(true);
          setRoleError("Please enter a valid role");


        }
        else {
          setRoleErrorFlag(false);
          setRoleError("");

        }
      }
      else {
        setRoleErrorFlag(true);
        setRoleError("Role must be  2 to 50 characters long");

      }
    }
    else {
      setRoleErrorFlag(true);
      setRoleError("Please enter role");


    }

  }

  function handleDepartment2(e) {

    setSendDepartment(e);
    setDepartment("")

    if (e.value) {

      setDepartmentErrorFlag(false);
      setDepartmentError("");

    }
    else {
      setSequnceList([])
      setDepartmentErrorFlag(true);
      setDepartmentError("Please select department");



    }
  }
  function handleDepartment(e) {

    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z-]/g, '');
    setDepartment(data.replace(/[^a-zA-Z-]/g, ''))

    if (data2) {

      if (data2.length >= 2) {
        setDepartmentErrorFlag(false);
        if (!nameRe2.test(data)) {
          setDepartmentErrorFlag(true);
          setDepartmentError("Please enter a valid department");


        }
        else {
          setDepartmentErrorFlag(false);
          setDepartmentError("");

        }
      }
      else {
        setDepartmentErrorFlag(true);
        setDepartmentError("Department must be  2 to 100 characters long");

      }
    }
    else {
      setDepartmentErrorFlag(true);
      setDepartmentError("Please enter department");


    }
  }

  function handleFname(e) {
    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z-']/g, '');
    setFname(data.replace(/[^a-zA-Z-']/g, ''))

    if (data2) {

      if (data2.length >= 2) {
        setNameErrorFlag(false);
        if (!nameRe2.test(data)) {
          setNameErrorFlag(true);
          setNameError("Please enter a valid name");


        }
        else {
          setNameErrorFlag(false);
          setNameError("");

        }
      }
      else {
        setNameErrorFlag(true);
        setNameError("Name must be  2 to 100 characters long");

      }
    }
    else {
      setNameErrorFlag(true);
      setNameError("Please enter name");


    }

  }

  function handleSubmit() {

    let data2 = fname.trim() == "" ? "" : fname.replace(/[^a-zA-Z-']/g, '');
    if (data2) {

      if (data2.length >= 2) {
        if (!nameRe2.test(fname)) {
          setNameError("Please enter a valid name");
        }
        else {
          setNameError("");

        }
      }
      else {
        setNameError("Name must be  2 to 100 characters long");

      }
    }
    else {
      setNameError("Please enter name");


    }


    if (sequnce) {

      setSequnceErrorFlag(false);
      setSequnceError("");
      if (!phoneRe.test(sequnce)) {
        setSequnceErrorFlag(true);
        setSequnceError("Please enter valid sequence ");


      }
      else {
        setSequnceErrorFlag(false);
        setSequnceError("");


      }

    }
    else {
      setSequnceErrorFlag(true);
      setSequnceError("Please enter sequence");

    }



    if (selectedFile) {
      setSelectedFileError("");

    }
    else {
      setSelectedFileError("Please select image");


    }

    if (sendDepartment && sendDepartment.value == "Others") {
      if (department) {

        // let data =e.target.value
        // let data2 = e.target.value.trim() == "" ? "" :  data.replace(/[^a-zA-Z-]/g, '');


        // setDepartment(data.replace(/[^a-zA-Z-]/g, ''))



        if (department.length >= 2) {
          if (!nameRe2.test(department)) {
            setDepartmentError("Please enter a valid department");
          }
          else {
            setDepartmentError("");
          }
        }
        else {
          setDepartmentError("Department must be  2 to 100 characters long");
        }

      }
      else {
        setDepartmentError("Please enter department");
      }
    }
    else {
      if (sendDepartment.value) {
        setDepartmentError("");
      }
      else {
        setDepartmentError("Please select department");

      }
    }



    let data3 = role.trim() == "" ? "" : role.replace(/[^a-zA-Z-]/g, '');


    setRole(role.replace(/[^a-zA-Z-]/g, ''))

    if (data3) {

      if (data3.length >= 2) {
        setRoleErrorFlag(false);
        if (!nameRe.test(role)) {
          setRoleErrorFlag(true);
          setRoleError("Please enter a valid role");


        }
        else {
          setRoleErrorFlag(false);
          setRoleError("");

        }
      }
      else {
        setRoleErrorFlag(true);
        setRoleError("Role must be  2 to 50 characters long");

      }
    }
    else {
      setRoleErrorFlag(true);
      setRoleError("Please enter role");


    }



    if (nameErrorFlag == false && sequnceErrorFlag == false && departmentErrorFlag == false && roleErrorFlag == false && role && fname && sequnce && selectedFile) {
      if (sendDepartment.value == "Others") {
        if (department) {
          MainFun(0);
        }
      }
      else {
        MainFun();
      }



    }
    else {
      debugger
    }
  }

  function MainFun(i) {
    let formData = new FormData();
    formData.append('image', selectedFile ? selectedFile : "");
    formData.append('role', role);
    formData.append('name', fname);
    formData.append('department', i == 0 ? department : sendDepartment.value);
    formData.append('sequence_No', sequnce);
    let data = propsValue ? propsValue.teammember_id ? propsValue.teammember_id : "" : ""
    if (data) {
      formData.append('teammember_id', data);
      dispatch(dispatchLoadingStatus(true));
      dispatch(ourTeamEdit_API(formData));
    }

    else {
      dispatch(dispatchLoadingStatus(true));
      dispatch(ourTeamAdd_API(formData));
    }
  }


  useEffect(() => {
    if (!token) {

      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }

  }, [])

  useEffect(() => {

    if (department.length > 0) {
      let formData = new FormData();

      formData.append('department', department);
      dispatch(ourTeamSequence_API(formData));

    }
    else {
      if (sendDepartment) {
        if (sendDepartment.value == "" || sendDepartment.value) {
          let formData = new FormData();
          formData.append('department', sendDepartment.value);

          dispatch(ourTeamSequence_API(formData));
        }
      }
    }
  }, [department, sendDepartment])


  useEffect(() => {
    if (ourTeamSequenceReducer) {
      setSequnceList(ourTeamSequenceReducer.data);
    }
    else {
      setSequnceList([]);
    }
  }, [ourTeamSequenceReducer])
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title"> {propsValue ? propsValue.teammember_id ? "Our Team Edit" : "Our Team Add" : "Our Team Add"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/OurTeamList">Our Team List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.teammember_id ? "Our Team Edit" : "Our Team Add" : "Our Team Add"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">

                          <div className=" row">


                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Name <span className='asteric-sign'>* </span></label>
                              <input type="text" maxLength={100} className="form-control" placeholder='Enter Name' value={fname} onChange={(e) => handleFname(e)} />
                              <p className='errorr'>{nameError}</p>
                            </div>
                            <div className="form-group col-sm-6" style={{ cursor: "pointer" }} >
                              <label htmlFor="fname">Department <span className="text-danger">*</span></label>

                              <Select className='w-100'
                                options={dataList ? [{ label: 'Select', value: '' }].concat(dataList.map((dataItem) => ({
                                  value: dataItem.value,
                                  label: dataItem.label,
                                }))) : [{ label: "Select", value: "" }]}

                                placeholder="Select"
                                isClearable={false}
                                value={sendDepartment}
                                onChange={(e) => handleDepartment2(e)}
                              />
                              {sendDepartment && sendDepartment.value == "Others" || department ?
                                <input className="form-control" placeholder='Enter Department' value={department} onChange={(e) => handleDepartment(e)} />
                                : ""}
                              <p className='errorr'>{departmentError}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Role <span className="text-danger">*</span></label>
                              <input type="text" maxLength={50} placeholder='Enter Role' className="form-control" value={role} onChange={(e) => handleRole(e)} />
                              <p className='errorr'>{roleError}</p>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Sequence <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder='Enter Sequence' value={sequnce} onChange={(e) => handleSequnce(e)} maxLength={2} />
                              <p className='errorr'>{sequnceError}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">Upload Image <span className="text-danger">*</span></label>
                                <form >
                                  <div className="file">
                                    <div className="file__input" id="file__input">
                                      <input className="file__input--file point"
                                        type="file"
                                        id="image"

                                        onChange={handleFileChange}
                                      />
                                      <label className="file__input--label" accept='.png, .jpg .jpeg' htmlFor="customFile" data-text-btn="Choose Files">{ImageName ? ImageName : "Add image:"}</label>
                                    </div>
                                  </div>

                                </form>
                                <p className='errorr'>{selectedFileError}</p>


                                {selectedFile && (
                                  <div className="choosed-files" style={{ position: 'relative' }}>
                                    <img
                                      src={typeof selectedFile === 'string' ? selectedFile : URL.createObjectURL(selectedFile)}
                                      alt="Selected Preview"
                                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />

                                    <CloseSvg className='point' width={18} height={18} onClick={() => handleRemoveFile()} style={{ zIndex: '99', position: 'absolute', right: '8px', top: '8px' }} />

                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group col-sm-6">
                              <label className="form-label">Used Sequence :</label>
                              <div className='d-flex Sequence-main-div'>
                                {
                                  sequnceList && sequnceList.length > 0 ? sequnceList.map((i) =>

                                    <p> {i.sequence_No}</p>
                                  ) : "No Sequence"
                                }
                              </div>
                            </div>

                          </div>
                          <button className="btn iq-bg-danger mr-2" onClick={() => handleMessage()}>Cancel</button>
                          <button className="btn btn-primary " onClick={() => handleSubmit()} >Submit</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Add