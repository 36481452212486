import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import {
  dispatchLoadingStatus,
  forgotPasswordOtpSendInEmail_API,
} from "../../API/Action/globalAction";

// Added by Mansi Vaghela
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const dispatch = useDispatch({});
  let history = useNavigate();

  function isValidEmail(email) {
    return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);
    if (e.target.value) {
      if (!isValidEmail(e.target.value)) {
        setEmailError(<p className='errorr' style={{ "color": "rgb(190, 49, 46)" }}>Please enter a valid email</p>)
      }
      else {
        setEmailError("")
      }
    }
    else {
      setEmailError(<p className='errorr' style={{ "color": "rgb(190, 49, 46)" }}>Please enter email</p>);
    }
  }

  const Otp = (e) => {
    if (email) {
      if (!isValidEmail(email)) {
        setEmailError(<p className="error" style={{ "color": "rgb(190, 49, 46)" }}>Please enter a valid email</p>);
      }
      else {
        setEmailError("");
      }
    }
    else {
      setEmailError(<p className="error" style={{ "color": "rgb(190, 49, 46)" }}>Please enter email</p>);
    }
    if (email) {
      setEmailError("");
      let formData = new FormData();
      formData.append("email", email);
      localStorage.setItem("sendEmail", email);
      localStorage.setItem("emailAdmin", email);
      localStorage.setItem("myotp", email);
      dispatch(dispatchLoadingStatus(true));
      dispatch(forgotPasswordOtpSendInEmail_API(formData));
    }
  };

  const forgotPasswordOtpSendInEmailReducer = useSelector(
    (state) => state.globalReducer.forgotPasswordOtpSendInEmailReducer
  );
  useEffect(() => {
    localStorage.removeItem("resendValue");
    localStorage.removeItem("emailAdmin");
    localStorage.removeItem("otp_id");
    localStorage.removeItem("admin_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem("password");
    localStorage.removeItem("myotp");
  }, []);

  useEffect(() => {
    if (forgotPasswordOtpSendInEmailReducer) {
      localStorage.setItem(
        "user_id",
        forgotPasswordOtpSendInEmailReducer
          ? forgotPasswordOtpSendInEmailReducer.user_id
          : ""
      );
      history("/Otp");
    }
  }, [forgotPasswordOtpSendInEmailReducer]);

  return (
    <section className="main-forget-pw sign-in-page bg-white">
      <div className="login-container">
        <Loader />

        <form class="login-form" onSubmit={(e) => e.preventDefault()}>
          <h1>Forgot Password</h1>
          <p>Please Fill the details to forgot your password</p>
          <div className="input-group">
            <label htmlFor="username" className="label">
              Email ID*
            </label>
            <input
              type="text"
              id="email"
              placeholder=" Email"
              value={email}
              onChange={handleEmail}
            />
            {emailError}
          </div>
          <button type="submit" onClick={Otp} class="btn btn-primary float-right">Submit</button>
        </form>
      </div>
    </section>
  );
}

export default ForgotPassword;
