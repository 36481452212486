import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Add from './Add';
import { useLocation } from 'react-router-dom';

// Added by Parth Roka
function Edit() {
  const { state } = useLocation();
  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <Add data={state} />
      </div>
    </>
  )
}

export default Edit