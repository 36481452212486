import React, { useEffect, useLayoutEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  dispatchLoadingStatus,
  userEdit_API,
  userRegister_API,
} from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import swal from "sweetalert";

const normalizeInput = (value = '', previousValue) => {
  if (!value || typeof value !== 'string') return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `+(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

// Added by Parth Roka
function Add(props) {
  const [eyes, setEyes] = useState(false);
  const [eyes2, setEyes2] = useState(false);
  const dispatch = useDispatch();
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [propsValue, setPropsValue] = useState();
  const [passwordError2, setPasswordError2] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordError44, setPasswordError44] = useState("");
  const [confirmPasswordError2, setConfirmPasswordError2] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailError2, setEmailError2] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneError2, setPhoneError2] = useState("");
  const [error, setError] = useState('');
  let token = localStorage.getItem("token");
  let nameRe2 = /^[a-zA-Z\s-']*$/;

  const toggleBtn = () => {
    setEyes(preState => !preState);
  }
  const toggleBtn2 = () => {
    setEyes2(preState => !preState);
  }

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/AdminUserManagementList";
      }
    });
  }

  const [selectedSections, setSelectedSections] = useState({
    "Home Page Highlights": { EnvironmentSelectionView: false, EnvironmentSelectionEdit: false, EnvironmentSelectionAll: false },
    Testimonials: { TestimonialsView: false, TestimonialsAdd: false, TestimonialsEdit: false, TestimonialsAll: false },
    Products: { ProductsView: false, ProductsAdd: false, ProductsEdit: false, ProductsAll: false },
    "Our Team": { OurTeamView: false, OurTeamAdd: false, OurTeamEdit: false, OurTeamAll: false },
    Pages: { PagesView: false, PagesAdd: false, PagesEdit: false, PagesAll: false },
    "Fabrics Category": { FabricsCategoryView: false, FabricsCategoryAdd: false, FabricsCategoryEdit: false, FabricsCategoryAll: false },
    Location: { ContactView: false, ContactAdd: false, ContactEdit: false, ContactAll: false },
    Inquiry: { InquiryView: false },
    "Admin User Management": { AdminUserManagementView: false, AdminUserManagementAdd: false, AdminUserManagementEdit: false, AdminUserManagementAll: false },
  });


  const actionDisplayNames = {
    HomeAdd: 'Add',
    HomeEdit: 'Edit',
    HomeView: 'View',
    HomeAll: 'All',

    PagesAdd: 'Add',
    PagesEdit: 'Edit',
    PagesView: 'View',
    PagesAll: 'All',

    ProductsAdd: 'Add',
    ProductsEdit: 'Edit',
    ProductsView: 'View',
    ProductsAll: 'All',

    OurTeamAdd: 'Add',
    OurTeamEdit: 'Edit',
    OurTeamView: 'View',
    OurTeamAll: 'All',

    FabricsCategoryAdd: 'Add',
    FabricsCategoryEdit: 'Edit',
    FabricsCategoryView: 'View',
    FabricsCategoryAll: 'All',

    DealsAdd: 'Add',
    DealsEdit: 'Edit',
    DealsView: 'View',
    DealsAll: 'All',

    PriceRangeAdd: 'Add',
    PriceRangeEdit: 'Edit',
    PriceRangeView: 'View',
    PriceRangeAll: 'All',

    ContactAdd: 'Add',
    ContactEdit: 'Edit',
    ContactView: 'View',
    ContactAll: 'All',

    InquiryView: 'View',

    TestimonialsAdd: 'Add',
    TestimonialsEdit: 'Edit',
    TestimonialsView: 'View',
    TestimonialsAll: 'All',

    AdminUserManagementAdd: 'Add',
    AdminUserManagementEdit: 'Edit',
    AdminUserManagementView: 'View',
    AdminUserManagementAll: 'All',

    EnvironmentSelectionEdit: 'Edit',
    EnvironmentSelectionView: 'View',
    EnvironmentSelectionAll: 'All'
  };

  const handleCheckboxChange = (section, action) => {
    console.log("action", action);
    setSelectedSections((prevSelected) => {
      const updatedSection = {
        ...prevSelected[section],
        [action]: !prevSelected[section][action],
      };

      if (action.endsWith('All')) {
        const allValue = updatedSection[action];
        Object.keys(updatedSection).forEach((key) => {
          if (!key.endsWith('All')) {
            updatedSection[key] = allValue;
          }
        });
      } else {
        if (action === 'HomeAdd' || action === 'HomeEdit') {
          updatedSection.HomeView = updatedSection.HomeAdd || updatedSection.HomeEdit;
          updatedSection.HomeAll = updatedSection.HomeAdd && updatedSection.HomeEdit;
        }

        else if (action === 'HomeView') {
          if (!updatedSection.HomeView) {
            updatedSection.HomeAdd = false;
            updatedSection.HomeEdit = false;
            updatedSection.HomeAll = false;
          }
        }

        else if (action === 'PagesAdd' || action === 'PagesEdit') {
          updatedSection.PagesView = updatedSection.PagesAdd || updatedSection.PagesEdit;
          updatedSection.PagesAll = updatedSection.PagesAdd && updatedSection.PagesEdit;

        }
        else if (action === 'PagesView') {
          if (!updatedSection.PagesView) {
            updatedSection.PagesAdd = false;
            updatedSection.PagesEdit = false;
            updatedSection.PagesAll = false;
          }
        }

        else if (action === 'ProductsAdd' || action === 'ProductsEdit') {
          updatedSection.ProductsView = updatedSection.ProductsAdd || updatedSection.ProductsEdit;
          updatedSection.ProductsAll = updatedSection.ProductsAdd && updatedSection.ProductsEdit;

        }
        else if (action === 'ProductsView') {
          if (!updatedSection.ProductsView) {
            updatedSection.ProductsAdd = false;
            updatedSection.ProductsEdit = false;
            updatedSection.ProductsAll = false;
          }
        }
        else if (action === 'OurTeamAdd' || action === 'OurTeamEdit') {
          updatedSection.OurTeamView = updatedSection.OurTeamAdd || updatedSection.OurTeamEdit;
          updatedSection.OurTeamAll = updatedSection.OurTeamAdd && updatedSection.OurTeamEdit;

        }
        else if (action === 'OurTeamView') {
          if (!updatedSection.OurTeamView) {
            updatedSection.OurTeamAdd = false;
            updatedSection.OurTeamEdit = false;
            updatedSection.OurTeamAll = false;
          }
        }
        else if (action === 'FabricsCategoryAdd' || action === 'FabricsCategoryEdit') {
          updatedSection.FabricsCategoryView = updatedSection.FabricsCategoryAdd || updatedSection.FabricsCategoryEdit;
          updatedSection.FabricsCategoryAll = updatedSection.FabricsCategoryAdd && updatedSection.FabricsCategoryEdit;

        }
        else if (action === 'FabricsCategoryView') {
          if (!updatedSection.FabricsCategoryView) {
            updatedSection.FabricsCategoryAdd = false;
            updatedSection.FabricsCategoryEdit = false;
            updatedSection.FabricsCategoryAll = false;
          }
        }
        else if (action === 'DealsAdd' || action === 'DealsEdit') {
          updatedSection.DealsView = updatedSection.DealsAdd || updatedSection.DealsEdit;
          updatedSection.DealsAll = updatedSection.DealsAdd && updatedSection.DealsEdit;

        }
        else if (action === 'DealsView') {
          if (!updatedSection.DealsView) {
            updatedSection.DealsAdd = false;
            updatedSection.DealsEdit = false;
            updatedSection.DealsAll = false;
          }
        }
        else if (action === 'PriceRangeAdd' || action === 'PriceRangeEdit') {
          updatedSection.PriceRangeView = updatedSection.PriceRangeAdd || updatedSection.PriceRangeEdit;
          updatedSection.PriceRangeAll = updatedSection.PriceRangeAdd && updatedSection.PriceRangeEdit;

        }
        else if (action === 'PriceRangeView') {
          if (!updatedSection.PriceRangeView) {
            updatedSection.PriceRangeAdd = false;
            updatedSection.PriceRangeEdit = false;
            updatedSection.PriceRangeAll = false;
          }
        }
        else if (action === 'ContactAdd' || action === 'ContactEdit') {
          updatedSection.ContactView = updatedSection.ContactAdd || updatedSection.ContactEdit;
          updatedSection.ContactAll = updatedSection.ContactAdd && updatedSection.ContactEdit;

        }
        else if (action === 'ContactView') {
          if (!updatedSection.ContactView) {
            updatedSection.ContactAdd = false;
            updatedSection.ContactEdit = false;
            updatedSection.ContactAll = false;
          }
        }
        else if (action === 'TestimonialsAdd' || action === 'TestimonialsEdit') {
          updatedSection.TestimonialsView = updatedSection.TestimonialsAdd || updatedSection.TestimonialsEdit;
          updatedSection.TestimonialsAll = updatedSection.TestimonialsAdd && updatedSection.TestimonialsEdit;

        }
        else if (action === 'TestimonialsView') {
          if (!updatedSection.TestimonialsView) {
            updatedSection.TestimonialsAdd = false;
            updatedSection.TestimonialsEdit = false;
            updatedSection.TestimonialsAll = false;
          }
        }
        else if (action === 'AdminUserManagementAdd' || action === 'AdminUserManagementEdit') {
          updatedSection.AdminUserManagementView = updatedSection.AdminUserManagementAdd || updatedSection.AdminUserManagementEdit;
          updatedSection.AdminUserManagementAll = updatedSection.AdminUserManagementAdd && updatedSection.AdminUserManagementEdit;

        }
        else if (action === 'AdminUserManagementView') {
          if (!updatedSection.AdminUserManagementView) {
            updatedSection.AdminUserManagementAdd = false;
            updatedSection.AdminUserManagementEdit = false;
            updatedSection.AdminUserManagementAll = false;
          }
        }
        else if (action === 'EnvironmentSelectionEdit') {
          updatedSection.EnvironmentSelectionView = updatedSection.EnvironmentSelectionEdit;
          updatedSection.EnvironmentSelectionAll = updatedSection.EnvironmentSelectionEdit;

        }
        else if (action === 'EnvironmentSelectionView') {
          if (!updatedSection.EnvironmentSelectionView) {
            updatedSection.EnvironmentSelectionEdit = false;
            updatedSection.EnvironmentSelectionAll = false;
          }
        }



        // const allChecked = Object.values(updatedSection)
        //   .filter((value, key) => !String(key).endsWith('All'))
        //   .every((value) => value);
        // updatedSection[`${section}All`] = allChecked;


      }

      return {
        ...prevSelected,
        [section]: updatedSection,
      };
    });
  };

  useLayoutEffect(() => {
    if (props.data) {
      console.log("accessData", props.data.accessData);
      setPropsValue(props.data);
      setFname(props.data.name);
      setEmail(props.data.email);
      setPhone(props.data.phone);

      let access = JSON.parse(props.data.accessData);

      setSelectedSections({

        "Home Page Highlights": {
          EnvironmentSelectionView: access.EnvironmentSelectionView ? access.EnvironmentSelectionView : false,
          EnvironmentSelectionEdit: access.EnvironmentSelectionEdit ? access.EnvironmentSelectionEdit : false,
          EnvironmentSelectionAll: access.EnvironmentSelectionAll ? access.EnvironmentSelectionAll : false,
        },

        Testimonials: {
          TestimonialsView: access.TestimonialsView ? access.TestimonialsView : false,
          TestimonialsAdd: access.TestimonialsAdd ? access.TestimonialsAdd : false,
          TestimonialsEdit: access.TestimonialsEdit ? access.TestimonialsEdit : false,
          TestimonialsAll: access.TestimonialsAll ? access.TestimonialsAll : false,
        },

        Products: {
          ProductsView: access.ProductsView ? access.ProductsView : false,
          ProductsAdd: access.ProductsAdd ? access.ProductsAdd : false,
          ProductsEdit: access.ProductsEdit ? access.ProductsEdit : false,
          ProductsAll: access.ProductsAll ? access.ProductsAll : false,

        },
        "Our Team": {
          OurTeamView: access.OurTeamView ? access.OurTeamView : false,
          OurTeamAdd: access.OurTeamAdd ? access.OurTeamAdd : false,
          OurTeamEdit: access.OurTeamEdit ? access.OurTeamEdit : false,
          OurTeamAll: access.OurTeamAll ? access.OurTeamAll : false,

        },
        Pages: {
          PagesView: access.PagesView ? access.PagesView : false,
          PagesAdd: access.PagesAdd ? access.PagesAdd : false,
          PagesEdit: access.PagesEdit ? access.PagesEdit : false,
          PagesAll: access.PagesAll ? access.PagesAll : false,

        },



        "Fabrics Category": {
          FabricsCategoryView: access.FabricsCategoryView ? access.FabricsCategoryView : false,
          FabricsCategoryAdd: access.FabricsCategoryAdd ? access.FabricsCategoryAdd : false,
          FabricsCategoryEdit: access.FabricsCategoryEdit ? access.FabricsCategoryEdit : false,
          FabricsCategoryAll: access.FabricsCategoryAll ? access.FabricsCategoryAll : false,

        },


        Location: {
          ContactView: access.ContactView ? access.ContactView : false,
          ContactAdd: access.ContactAdd ? access.ContactAdd : false,
          ContactEdit: access.ContactEdit ? access.ContactEdit : false,
          ContactAll: access.ContactAll ? access.ContactAll : false,

        },

        Inquiry: {
          InquiryView: access.InquiryView ? access.InquiryView : false,
        },



        "Admin User Managment": {
          AdminUserManagementView: access.AdminUserManagementView ? access.AdminUserManagementView : false,
          AdminUserManagementAdd: access.AdminUserManagementAdd ? access.AdminUserManagementAdd : false,
          AdminUserManagementEdit: access.AdminUserManagementEdit ? access.AdminUserManagementEdit : false,
          AdminUserManagementAll: access.AdminUserManagementAll ? access.AdminUserManagementAll : false,
        },

      });
    }





  }, [props])

  useEffect(() => {
    if (!token) {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
  }, []);

  function isValidEmail(email) {
    return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
  }

  function handleEmail(e) {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);
    if (e.target.value) {
      if (!isValidEmail(e.target.value)) {
        setEmailError(true);
        setEmailError2("Please enter valid email");
      } else {
        setEmailError(false);
        setEmailError2("");
      }
    } else {
      setEmailError(false);
      setEmailError2("Please enter email");
    }
  }

  function handlePhone(e) {
    let data2 = e.target.value ? normalizeInput(e.target.value, phone) : "";
    setPhone(normalizeInput(e.target.value, phone));
    if (data2 && data2.length == 0) {
      setPhoneError(true);
      setPhoneError2("Please enter phone");
    }
    else {
      if (data2 && data2.length == 15) {
        setPhoneError(false);
        setPhoneError2("");
      } else {
        setPhoneError(true);
        setPhoneError2("Please enter a valid phone");
      }
    }
    if (e.target.value.length == 0) {
      setPhoneError(true);
      setPhoneError2("Please enter phone");
    }

  }

  function handleFname(e) {
    let data = e.target.value
    let data2 = e.target.value.trim() == "" ? "" : data.replace(/[^a-zA-Z-']/g, '');

    setFname(data.replace(/[^a-zA-Z-']/g, ''))

    if (data2) {

      if (data2.length >= 2) {
        setNameError(false);
        if (!nameRe2.test(data)) {
          setNameError(true);
          setNameError2("Please enter a valid name");


        }
        else {
          setNameError(false);
          setNameError2("");

        }
      }
      else {
        setNameError(true);
        setNameError2("Name must be  2 to 100 characters long");

      }
    }
    else {
      setNameError(true);
      setNameError2("Please enter name");


    }
  }

  const handlePassword = (e) => {
    setPassword(e.target.value.trim());

    if (!e.target.value) {
      setPasswordError(true);
      setPasswordError2("Please enter password");
    }
    else {
      if (e.target.value.length >= 6 && e.target.value.length <= 16) {
        setPasswordError2("");
        setPasswordError(false);


      }
      else {
        setPasswordError(true);
        setPasswordError2("Password must be  6 to 16 characters long");
      }
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value.trim());
    if (!e.target.value) {
      setConfirmPasswordError2("Please enter confirm password");
    } else {


      if (e.target.value.length >= 6 && e.target.value.length <= 16) {
        setConfirmPasswordError2("");
        setPasswordError44(false);
        if (password) {

          if (password === e.target.value) {
            setConfirmPasswordError2("");

          }
          else {
            setConfirmPasswordError2("Password and confirmed password do not match");
          }
        }


      }
      else {
        setPasswordError44(true);
        setConfirmPasswordError2("Password must be  6 to 16 characters long");
      }

    }


  };


  function handleSubmit() {
    const payload = Object.assign({}, ...Object.entries(selectedSections).map(([section, actions]) => actions));

    const isAnyTrue = Object.values(payload).includes(true);
    const hasSelectedCheckbox = Object.values(selectedSections)
      .flatMap((actions) => Object.values(actions).filter((action) => action !== true))
      .some((value) => value);

    if (isAnyTrue == false) {
      setError('Please select at least one checkbox');
    } else {
      setError('');
    }

    let data2 = fname.trim() == "" ? "" : fname.replace(/[^a-zA-Z-']/g, '');

    if (data2) {

      if (data2.length >= 2) {
        if (!nameRe2.test(fname)) {
          setNameError2("Please enter a valid name");
        }
        else {
          setNameError2("");

        }
      }
      else {
        setNameError2("Name must be  2 to 100 characters long");

      }
    }
    else {
      setNameError2("Please enter name");


    }

    if (email) {
      if (!isValidEmail(email)) {
        setEmailError2("Please enter valid email");
      } else {
        setEmailError2("");
      }
    } else {
      setEmailError2("Please enter email");
    }


    if (phone && phone.length == 0) {
      setPhoneError(true);
      setPhoneError2("Please enter phone");
    }
    else {

      if (phone && phone.length == 15) {

        setPhoneError(false);
        setPhoneError2("");


      } else {
        setPhoneError(true);
        setPhoneError2("Please enter a valid phone");
      }
    }
    if (phone.length == 0) {
      setPhoneError(true);
      setPhoneError2("Please enter phone");
    }

    let data = propsValue
      ? propsValue.user_id
        ? propsValue.user_id
        : ""
      : "";

    if (!data) {
      if (password) {
        setPasswordError2("");
      } else {
        setPasswordError2("Please enter password");
      }

      if (confirmPassword) {
        setConfirmPasswordError2("");
      } else {
        setConfirmPasswordError2("Please enter confirm password");
      }
    }

    if (nameError == false && emailError == false && phoneError == false && fname && email && phone && isAnyTrue == true) {
      let formData = new FormData();
      formData.append("password", password);
      formData.append("name", fname);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("accessData", JSON.stringify(payload));

      if (data) {
        formData.append("user_id", data);
        localStorage.setItem("user_id", data)
        dispatch(userEdit_API(formData));
        dispatch(dispatchLoadingStatus(true));
      } else {
        if (passwordError == false && passwordError44 == false && confirmPasswordError == false && confirmPassword && password) {
          dispatch(userRegister_API(formData));
          dispatch(dispatchLoadingStatus(true));
        }

      }
    }

  }

  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">   {propsValue ? propsValue.user_id ? "Edit User" : "Assign User" : "Assign User"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/AdminUserManagementList">Admin User Management List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.user_id ? "Edit User" : "Assign User" : "Assign User"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row ">
                            <div className="form-group col-sm-6">
                              <label for="fname">Name <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                value={fname}
                                placeholder="Enter Name"
                                maxLength={100}
                                onChange={(e) => handleFname(e)}
                              />
                              <p className="errorr">{nameError2}</p>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Email <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                placeholder="Enter Email"
                                className="form-control"
                                value={email}
                                onChange={(e) => handleEmail(e)}
                              />
                              <p className="errorr">{emailError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Phone <span className="text-danger">*</span></label>
                              <input
                                className="form-control"
                                placeholder="Enter Phone"
                                value={phone}
                                maxLength={18}
                                onChange={(e) => handlePhone(e)}
                              />
                              <p className="errorr">{phoneError2}</p>

                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="exampleInputPassword1">
                                Password <span className="text-danger">{propsValue ? propsValue.user_id ? "" : "*" : "*"}</span>
                              </label>

                              <div style={{ position: "relative" }}>
                                <input
                                  autoComplete="new-password" id="Passwordd"
                                  type={eyes ? "text" : "password"}
                                  className="form-control mb-0"
                                  // id="exampleInputPassword1"
                                  placeholder="Enter Password"
                                  value={password}
                                  onChange={handlePassword}
                                  maxLength={16}
                                />
                                <button style={{ position: 'absolute', top: '-5px', right: '0' }} className="btn " type="button" id="password-addon" onClick={toggleBtn}>
                                  {
                                    eyes ? <FaEye style={{ width: "18", height: "18" }} /> : <FaEyeSlash style={{ width: "18", height: "18" }} />
                                  }
                                </button>
                              </div>
                              <p className="errorr"> {passwordError2}  </p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="exampleInputPassword1">
                                Confirm Password <span className="text-danger">{propsValue ? propsValue.user_id ? "" : "*" : "*"}</span>
                              </label>

                              <div style={{ position: "relative" }}>
                                <input
                                  type={eyes2 ? "text" : "password"}
                                  className="form-control mb-0"
                                  id="exampleInputPassword1"
                                  placeholder="Enter Confirm Password"
                                  value={confirmPassword}
                                  onChange={handleConfirmPassword}
                                  maxLength={16}

                                />
                                <button style={{ position: 'absolute', top: '-5px', right: '0' }} className="btn " type="button" id="password-addon" onClick={toggleBtn2}>
                                  {
                                    eyes2 ? <FaEye style={{ width: "18", height: "18" }} /> : <FaEyeSlash style={{ width: "18", height: "18" }} />
                                  }
                                </button>
                              </div>
                              <p className="errorr"> {confirmPasswordError2} </p>
                            </div>


                            <div className="col-md-12">

                              <label
                                htmlFor="inputName"
                                className=" col-form-label color-dark fs-14 fw-500 align-center"
                              >
                                Preferred Roles <span className="text-danger">*</span>
                              </label>


                              {Object.keys(selectedSections).map((section) => (

                                <div className="form-group row align-items-center">

                                  <div className="col-md-3" key={section}>
                                    <label
                                      htmlFor="inputName"
                                      className=" col-form-label color-dark fs-14 fw-500 align-center"
                                    >
                                      {section}
                                    </label>

                                  </div>


                                  <div className="col-md-9">
                                    <div className="custom-role-labels">

                                      {Object.keys(selectedSections[section])
                                        .filter((action) => action !== 'All')

                                        .map((action) => (
                                          // <li key={`${section}-${action}`}>
                                          <label className="point">
                                            <input
                                              className="point"
                                              type="checkbox"
                                              checked={selectedSections[section][action]}
                                              onChange={() => handleCheckboxChange(section, action)}
                                            />
                                            {` ${actionDisplayNames[action]}`}
                                          </label>
                                          // </li>
                                        ))}

                                    </div>
                                  </div>

                                </div>
                              ))}
                              <p className="errorr">{error}</p>


                            </div>

                          </div>

                          <button type="reset" className="btn iq-bg-danger mr-2" onClick={() => handleMessage()}>
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
